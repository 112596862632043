<template>
  <div class="reports-content">
    <h1>Reports</h1>
    <div class="content-container">
      <!-- Show reports if they exist -->
      <div v-if="assessmentData.final_report_url" class="reports-section">
        <h2>Project Reports</h2>
        <!-- Show assessment report -->
        <div class="report-card">
          <div class="report-info">
            <h3>Initial Assessment Report</h3>
            <p>Your AI readiness assessment results and recommendations</p>
            <p class="date">Generated: {{ formatDate(assessmentData.completed_at) }}</p>
            <div class="report-stats">
              <div class="stat">
                <span class="label">Grade:</span>
                <span class="value">{{ assessmentData.grade }}</span>
              </div>
              <div class="stat">
                <span class="label">Score:</span>
                <span class="value">{{ assessmentData.score }}%</span>
              </div>
            </div>
          </div>
          <button @click="viewAssessment" class="view-button">View Assessment</button>
        </div>

        <!-- Show discovery report if it exists -->
        <div v-if="assessmentData.discovery_answers" class="report-card">
          <div class="report-info">
            <h3>Discovery Call Report</h3>
            <p>Detailed findings from your discovery call with our team</p>
            <p class="date">Generated: {{ formatDate(assessmentData.discovery_date) }}</p>
          </div>
          <button @click="viewDiscovery" class="view-button">View Report</button>
        </div>

        <!-- Show proposal report if it exists -->
        <div v-if="assessmentData.proposal_url" class="report-card">
          <div class="report-info">
            <h3>Custom Proposal</h3>
            <p>Your tailored AI implementation proposal</p>
            <p class="date">Generated: {{ formatDate(assessmentData.proposal_date_completed) }}</p>
          </div>
          <button @click="viewProposal" class="view-button">View Proposal</button>
        </div>
      </div>

      <!-- Show placeholder content if no reports exist -->
      <div v-else class="reports-cta">
        <div class="cta-content">
          <img 
            src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731685748/image2_k2evij.png" 
            alt="Reports Coming Soon"
            class="cta-image"
          >
          <h2>Your Reports Are Coming Soon</h2>
          <p>
            As we progress through the discovery process and interview stakeholders, 
            your detailed reports will be generated here. These reports will provide 
            comprehensive insights into your AI implementation strategy.
          </p>
          <div class="report-timeline">
            <div class="timeline-item">
              <div class="timeline-icon">1</div>
              <div class="timeline-text">
                <h4>Initial Assessment</h4>
                <p>AI Readiness Report</p>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-icon">2</div>
              <div class="timeline-text">
                <h4>Discovery Call</h4>
                <p>Process Analysis Report</p>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-icon">3</div>
              <div class="timeline-text">
                <h4>Final Report</h4>
                <p>Implementation Strategy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportsContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return 'Not available';
      return new Date(date).toLocaleDateString();
    },
    viewAssessment() {
      this.$emit('navigate', 'AssessmentContent');
    },
    viewDiscovery() {
      this.$emit('navigate', 'DiscoveryCallContent');
    },
    viewProposal() {
      if (this.assessmentData.proposal_url) {
        window.open(this.assessmentData.proposal_url, '_blank');
      }
    }
  }
}
</script>

<style scoped>
.reports-content {
  padding: 2rem;
}

.content-container {
  max-width: 800px;
  margin: 2rem auto;
}

.reports-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reports-list {
  margin-top: 2rem;
}

.report-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
}

.report-info h3 {
  margin: 0;
  color: var(--accent-victory-green);
}

.report-info p {
  margin: 0.5rem 0;
  color: #666;
}

.date {
  font-size: 0.9rem;
  color: #999;
}

.view-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.report-stats {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat .label {
  font-size: 0.9rem;
  color: #666;
}

.stat .value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--accent-victory-green);
}

.reports-cta {
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-image {
  width: 300px;
  height: auto;
  object-fit: cover;
  margin-bottom: 2rem;
  border-radius: 8px;
}

.cta-content h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.cta-content p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.report-timeline {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 8px;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
  position: relative;
}

.timeline-item:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -50%;
  top: 25px;
  width: 100%;
  height: 2px;
  background-color: var(--accent-victory-green);
  opacity: 0.3;
}

.timeline-icon {
  width: 50px;
  height: 50px;
  background-color: var(--accent-victory-green);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.timeline-text {
  text-align: center;
}

.timeline-text h4 {
  color: var(--accent-victory-green);
  margin: 0;
  font-size: 1.1rem;
}

.timeline-text p {
  margin: 0.5rem 0 0;
  font-size: 0.9rem;
  color: #666;
}
</style>
