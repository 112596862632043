<template>
  <div class="pricing-container" ref="pricingContainer">
    <div class="pricing-header">Pricing</div>
    <PricingCards 
      ref="pricingCards" 
      @reserve-project="reserveProject"
      @reserve-department="reserveDepartment"
      @reserve-organization="reserveOrganization"
    />
  </div>
</template>

<script>
import PricingCards from '@/components/PricingCards.vue'

export default {
  name: 'PricingView',
  components: {
    PricingCards
  },
  data() {
    return {
      isMobile: false,
      touchStartX: null,
      touchEndX: null
    }
  },
  created() {
    this.$emit('update-pricing-view', true);
  },
  unmounted() {
    this.$emit('update-pricing-view', false);
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    reserveProject() {
      window.open('https://buy.stripe.com/7sI5o43Xg6wecQo3cj', '_blank');
    },
    reserveDepartment() {
      window.open('https://buy.stripe.com/fZe9Ek9hAcUC9Ec5kp', '_blank');
    },
    reserveOrganization() {
      window.open('https://buy.stripe.com/cN217O2Tc4o68A8eV0', '_blank');
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
}
</script>

<style scoped>
.pricing-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, var(--primary-background-cream), var(--secondary-background-sky-blue));
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pricing-header {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--accent-victory-green);
  z-index: 10;
  text-align: center;
  width: 100%;
  display: none;
}

@media (max-width: 768px) {
  .pricing-container {
    padding: 20px 0;
    align-items: flex-start;
    padding-top: 50px;
  }

  .pricing-header {
    display: block;
    top: 60px;
    margin-bottom: 40px;
    padding-bottom: 20px;
  }
}
</style>
