<template>
  <div class="chat-interface">
    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Delete Conversation</h3>
        <p>Are you sure you want to delete this conversation? This action cannot be undone.</p>
        <div class="modal-actions">
          <button 
            @click="cancelDelete" 
            class="cancel-button"
          >
            Cancel
          </button>
          <button 
            @click="confirmDelete" 
            class="delete-button"
          >
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Delete Message Modal -->
    <div v-if="showDeleteMessageModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Delete Message</h3>
        <p>Are you sure you want to delete this message? This action cannot be undone.</p>
        <div class="modal-actions">
          <button 
            @click="cancelDeleteMessage" 
            class="cancel-button"
          >
            Cancel
          </button>
          <button 
            @click="confirmDeleteMessage" 
            class="delete-button"
          >
            Delete
          </button>
        </div>
      </div>
    </div>

    <!-- Conversation Sidebar -->
    <div class="conversation-sidebar">
      <div class="conversation-groups">
        <div class="time-group">
          <div class="time-group-header">
            <div class="time-label">Today</div>
            <button 
              @click="startNewChat" 
              class="new-chat-button"
              title="Start new chat"
            >
              <svg viewBox="0 0 24 24" class="new-chat-icon">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
              </svg>
            </button>
          </div>
          <div 
            v-for="chat in todayConversations" 
            :key="chat.id"
            :class="['conversation-item', { active: currentChatId === chat.id }]"
            @click="loadChat(chat.id)"
          >
            <span class="conversation-title">{{ chat.title }}</span>
            <button 
              class="delete-conversation-button"
              @click.stop="promptDelete(chat)"
              title="Delete conversation"
            >
              <svg viewBox="0 0 24 24" class="delete-icon">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
              </svg>
            </button>
          </div>
        </div>
        
        <div class="time-group">
          <div class="time-label">Yesterday</div>
          <div 
            v-for="chat in yesterdayConversations" 
            :key="chat.id"
            :class="['conversation-item', { active: currentChatId === chat.id }]"
            @click="loadChat(chat.id)"
          >
            <span class="conversation-title">{{ chat.title }}</span>
            <button 
              class="delete-conversation-button"
              @click.stop="promptDelete(chat)"
              title="Delete conversation"
            >
              <svg viewBox="0 0 24 24" class="delete-icon">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
              </svg>
            </button>
          </div>
        </div>
        
        <div class="time-group">
          <div class="time-label">Previous</div>
          <div 
            v-for="chat in olderConversations" 
            :key="chat.id"
            :class="['conversation-item', { active: currentChatId === chat.id }]"
            @click="loadChat(chat.id)"
          >
            <span class="conversation-title">{{ chat.title }}</span>
            <button 
              class="delete-conversation-button"
              @click.stop="promptDelete(chat)"
              title="Delete conversation"
            >
              <svg viewBox="0 0 24 24" class="delete-icon">
                <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Area -->
    <div class="chat-area">
      <!-- Messages Container -->
      <div class="messages-container" ref="messagesContainer">
        <!-- Welcome message when no messages -->
        <div v-if="messages.length === 0" class="welcome-message">
          <h1>What would you like to talk about?</h1>
        </div>

        <!-- Existing messages code -->
        <div 
          v-for="(message, index) in messages" 
          :key="index"
          :class="['message', message.type]"
        >
          <div class="message-avatar">
            <!-- Use appropriate icons based on sender -->
            <div v-if="message.type === 'user'" class="user-avatar">U</div>
            <div v-else class="ai-avatar">AI</div>
          </div>
          <div class="message-content">
            <div class="message-header">
              <button 
                class="delete-message-button"
                @click="promptDeleteMessage(index)"
                title="Delete message"
              >
                <svg viewBox="0 0 24 24" class="delete-icon">
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                </svg>
              </button>
            </div>
            <div 
              class="message-text"
              v-html="formatMessage(message.text)"
            ></div>
            <div class="message-timestamp">{{ formatTimestamp(message.timestamp) }}</div>
          </div>
        </div>
        
        <!-- Loading indicator -->
        <div v-if="isTyping" class="message ai">
          <div class="message-avatar">
            <div class="ai-avatar">AI</div>
          </div>
          <div class="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <!-- Input Area -->
      <div class="input-container">
        <div class="input-wrapper">
          <textarea
            v-model="newMessage"
            @keydown.enter.exact.prevent="sendMessage"
            @keydown.shift.enter.exact="newLine"
            placeholder="Message ChatGPT..."
            rows="1"
            ref="messageInput"
            @input="autoResize"
          ></textarea>
          <button 
            @click="sendMessage"
            :disabled="!newMessage.trim()"
            class="send-button"
          >
            <svg viewBox="0 0 24 24" class="send-icon">
              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendChatMessage, getConversations, getConversation, deleteMessage } from '@/services/api';
import { mapState } from 'vuex';

export default {
  name: 'ChatInterface',
  data() {
    return {
      messages: [],
      newMessage: '',
      isTyping: false,
      currentChatId: null,
      conversations: [],
      showDeleteModal: false,
      conversationToDelete: null,
      showDeleteMessageModal: false,
      messageIndexToDelete: null,
    }
  },
  computed: {
    ...mapState(['user']),
    
    // Filter conversations by date
    todayConversations() {
      const today = new Date().toDateString();
      return this.conversations.filter(chat => {
        const chatDate = new Date(chat.updated_at).toDateString();
        return chatDate === today;
      });
    },
    yesterdayConversations() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayString = yesterday.toDateString();
      return this.conversations.filter(chat => {
        const chatDate = new Date(chat.updated_at).toDateString();
        return chatDate === yesterdayString;
      });
    },
    olderConversations() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return this.conversations.filter(chat => {
        const chatDate = new Date(chat.updated_at);
        return chatDate < yesterday;
      });
    }
  },
  methods: {
    async loadConversations() {
      try {
        const conversations = await getConversations();
        this.conversations = conversations;
      } catch (error) {
        console.error('Error loading conversations:', error);
      }
    },
    async loadChat(chatId) {
      this.currentChatId = chatId;
      try {
        const response = await getConversation(chatId);
        
        // Check if we have a valid response with messages
        if (!response || !response.messages) {
          console.error('Invalid response format:', response);
          throw new Error('Invalid conversation data received');
        }

        // Transform the messages format, filtering out any null/undefined messages
        this.messages = response.messages
          .filter(msg => msg.user_message || msg.ai_response) // Only include messages that have content
          .map(msg => {
            const messages = [];
            if (msg.user_message) {
              messages.push({
                type: 'user',
                text: msg.user_message,
                timestamp: new Date(msg.timestamp)
              });
            }
            if (msg.ai_response) {
              messages.push({
                type: 'ai',
                text: msg.ai_response,
                timestamp: new Date(msg.timestamp)
              });
            }
            return messages;
          })
          .flat();
        
        // Scroll to bottom after loading messages
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } catch (error) {
        console.error('Error loading chat:', error);
        // Show error message to user
        this.messages = [{
          type: 'system',
          text: 'Error loading conversation. Please try again.',
          timestamp: new Date()
        }];
      }
    },
    async sendMessage() {
      if (!this.newMessage.trim()) return;
      
      // Add user message
      const userMessage = {
        type: 'user',
        text: this.newMessage,
        timestamp: new Date()
      };
      
      this.messages.push(userMessage);
      const messageToSend = this.newMessage;
      this.newMessage = '';
      
      // Reset input height
      if (this.$refs.messageInput) {
        this.$refs.messageInput.style.height = 'auto';
      }

      // Show typing indicator
      this.isTyping = true;

      // Scroll to bottom after sending
      this.$nextTick(() => {
        this.scrollToBottom();
      });

      try {
        // Send message to API with current messages array
        const response = await sendChatMessage(
          messageToSend, 
          this.messages,
          this.currentChatId
        );
        
        // Hide typing indicator
        this.isTyping = false;

        // Add AI response
        this.messages.push({
          type: 'ai',
          text: response.response,
          timestamp: new Date()
        });

        // Update conversation ID
        if (response.conversation_id) {
          this.currentChatId = response.conversation_id;
        }

        // If this was a new conversation, refresh the conversation list
        if (response.title) {
          await this.loadConversations();
        }

        // Scroll to bottom after receiving response
        this.$nextTick(() => {
          this.scrollToBottom();
        });

      } catch (error) {
        console.error('Error sending message:', error);
        this.isTyping = false;
        this.messages.push({
          type: 'system',
          text: 'Sorry, there was an error processing your message. Please try again.',
          timestamp: new Date()
        });
        
        // Scroll to bottom even on error
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    formatTimestamp(date) {
      return new Date(date).toLocaleTimeString([], { 
        hour: '2-digit', 
        minute: '2-digit' 
      });
    },
    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      if (container) {
        // Use smooth scrolling
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    autoResize(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    startNewChat() {
      // Clear current chat state
      this.messages = [];
      this.currentChatId = null;
      
      // Show welcome message
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.scrollTop = 0;
        }
      });
    },
    promptDelete(chat) {
      this.conversationToDelete = chat;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      if (!this.conversationToDelete) return;

      try {
        await this.$store.dispatch('deleteConversation', this.conversationToDelete.id);
        
        // If we're deleting the current conversation, clear it
        if (this.currentChatId === this.conversationToDelete.id) {
          this.startNewChat();
        }
        
        // Refresh conversations list
        await this.loadConversations();
        
      } catch (error) {
        console.error('Error deleting conversation:', error);
        // You might want to show an error message to the user
      }

      this.showDeleteModal = false;
      this.conversationToDelete = null;
    },
    cancelDelete() {
      this.showDeleteModal = false;
      this.conversationToDelete = null;
    },
    formatMessage(text) {
      if (!text) return '';
      
      return text
        // Handle numbered lists (must come before bold)
        .replace(/^\d+\.\s+/gm, (match) => `<span class="list-number">${match}</span>`)
        
        // Handle bold text with **
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        
        // Handle bullet points
        .replace(/^•\s+/gm, '<span class="bullet-point">• </span>')
        
        // Convert line breaks to <br>
        .replace(/\n/g, '<br>');
    },
    promptDeleteMessage(index) {
      this.messageIndexToDelete = index;
      this.showDeleteMessageModal = true;
    },
    async confirmDeleteMessage() {
      if (this.messageIndexToDelete === null || !this.currentChatId) return;

      try {
        const response = await deleteMessage(this.currentChatId, this.messageIndexToDelete);
        
        // Update local messages with filtered response messages
        this.messages = response.messages
          .filter(msg => msg.user_message || msg.ai_response) // Only include messages that have content
          .map(msg => {
            const messages = [];
            if (msg.user_message) {
              messages.push({
                type: 'user',
                text: msg.user_message,
                timestamp: new Date(msg.timestamp)
              });
            }
            if (msg.ai_response) {
              messages.push({
                type: 'ai',
                text: msg.ai_response,
                timestamp: new Date(msg.timestamp)
              });
            }
            return messages;
          })
          .flat();
        
      } catch (error) {
        console.error('Error deleting message:', error);
        // Show error message to user
      }

      this.showDeleteMessageModal = false;
      this.messageIndexToDelete = null;
    },
    cancelDeleteMessage() {
      this.showDeleteMessageModal = false;
      this.messageIndexToDelete = null;
    },
    newLine(event) {
      // Allow the default behavior (new line) when Shift is held
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      
      // Insert new line at cursor position
      this.newMessage = this.newMessage.substring(0, start) + "\n" + this.newMessage.substring(end);
      
      // Move cursor after new line
      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 1;
        this.autoResize(event);
      });
    }
  },
  async mounted() {
    // Load conversations when component mounts
    await this.loadConversations();
    
    // Load specific chat if ID is in route
    if (this.$route.params.chatId) {
      await this.loadChat(this.$route.params.chatId);
    }
  }
}
</script>

<style scoped>
.chat-interface {
  display: flex;
  height: 100vh;
  background-color: var(--primary-background-cream);
  color: var(--main-text-color);
  width: 100%;
  overflow: hidden;
}

.conversation-sidebar {
  width: 260px;
  border-right: 1px solid var(--accent-victory-green);
  background-color: #f4f4f4;
  overflow-y: auto;
}

.conversation-groups {
  padding: 10px;
}

.time-group {
  margin-bottom: 20px;
}

.time-label {
  font-size: 0.8em;
  color: #666;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.conversation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 2px;
  transition: all 0.2s ease;
  font-size: 0.9em;
}

.conversation-item:hover {
  background-color: var(--secondary-background-sky-blue);
  color: white;
}

.conversation-item.active {
  background-color: var(--accent-victory-green);
  color: white;
}

.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px 40px;
  scroll-behavior: smooth;
  margin-bottom: 0;
  margin-right: 25px;
}

.message {
  display: flex;
  padding: 20px;
  gap: 20px;
  margin-bottom: 2px;
  width: 100%;
}

.message.user {
  background-color: var(--primary-background-cream);
  flex-direction: row-reverse;
  justify-content: flex-start;
  border-radius: 20px 20px 0 20px;
}

.message.ai {
  background-color: var(--secondary-background-sky-blue);
  justify-content: flex-start;
  border-radius: 20px 20px 20px 0;
}

.message-avatar {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.user-avatar {
  background-color: var(--accent-victory-green);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.ai-avatar {
  background-color: var(--accent-victory-green);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.message-content {
  flex-grow: 0;
  max-width: 80%;
}

.message.user .message-content {
  text-align: right;
}

.message.ai .message-content {
  text-align: left;
}

.message-text {
  line-height: 1.6;
  white-space: pre-wrap;
}

.message-text :deep(strong) {
  color: var(--accent-victory-green);
  font-weight: 600;
}

.message-text :deep(.list-number) {
  color: var(--accent-victory-green);
  font-weight: 600;
  margin-right: 4px;
}

.message-text :deep(.bullet-point) {
  color: var(--accent-victory-green);
  font-weight: 600;
  margin-right: 4px;
}

.message-timestamp {
  font-size: 0.8em;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.input-container {
  border-top: 1px solid var(--accent-victory-green);
  padding: 20px 40px;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  background-color: white;
  border: 1px solid var(--accent-victory-green);
  border-radius: 6px;
  padding: 8px;
}

textarea {
  flex-grow: 1;
  padding: 8px;
  border: none;
  background-color: transparent;
  color: var(--main-text-color);
  font-size: 1rem;
  line-height: 1.5;
  resize: none;
  max-height: 200px;
  min-height: 24px;
}

textarea:focus {
  outline: none;
}

.send-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-victory-green);
  border: none;
  cursor: pointer;
  padding: 8px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.send-button:hover {
  background-color: var(--accent-sunny-yellow);
}

.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.send-icon {
  width: 18px;
  height: 18px;
  fill: white;
}

.send-button:hover .send-icon {
  fill: var(--accent-victory-green);
}

.typing-indicator {
  display: flex;
  gap: 5px;
  padding: 10px;
  justify-content: flex-start;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: var(--accent-victory-green);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

/* Scrollbar Styling */
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background: var(--primary-background-cream);
}

.messages-container::-webkit-scrollbar-thumb {
  background: var(--accent-victory-green);
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: var(--accent-sunny-yellow);
}

.welcome-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.welcome-message h1 {
  color: var(--accent-victory-green);
  font-size: 2rem;
  font-weight: 500;
  opacity: 0.8;
}

.time-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.new-chat-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.new-chat-button:hover {
  background-color: var(--accent-victory-green);
}

.new-chat-icon {
  width: 20px;
  height: 20px;
  fill: var(--accent-victory-green);
}

.new-chat-button:hover .new-chat-icon {
  fill: white;
}

.conversation-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-conversation-button {
  opacity: 0;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-item:hover .delete-conversation-button {
  opacity: 1;
}

.delete-icon {
  width: 16px;
  height: 16px;
  fill: #666;
  transition: fill 0.2s ease;
}

.delete-conversation-button:hover .delete-icon {
  fill: #ff4444;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
}

.modal-content h3 {
  margin-top: 0;
  color: var(--accent-victory-green);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.modal-actions button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #666;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.delete-button {
  background-color: #ff4444;
  color: white;
}

.delete-button:hover {
  background-color: #ff2020;
}

/* Add spacing between paragraphs */
.message-text :deep(br) {
  margin-bottom: 0.5em;
  display: block;
  content: "";
}

/* Add list styling */
.message-text :deep(br) + :deep(.list-number),
.message-text :deep(br) + :deep(.bullet-point) {
  margin-top: 0.5em;
  display: inline-block;
}

.message-header {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.message:hover .message-header {
  opacity: 1;
}

.delete-message-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-message-button .delete-icon {
  width: 16px;
  height: 16px;
  fill: #666;
  transition: fill 0.2s ease;
}

.delete-message-button:hover .delete-icon {
  fill: #ff4444;
}
</style> 