<template>
  <div class="projects-content">
    <h1>Projects</h1>
    <div class="content-container">
      <div class="projects-cta">
        <div class="cta-content">
          <img 
            src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731685748/image2_k2evij.png" 
            alt="Projects Coming Soon"
            class="cta-image"
          >
          <h2>Your AI Projects Dashboard</h2>
          <p>
            After our discovery process, this is where you'll be able to view and manage 
            all of your recommended AI implementation projects. Each project will include:
          </p>
          <div class="features-grid">
            <div class="feature">
              <h3>Project Details</h3>
              <p>Comprehensive scope and implementation timeline</p>
            </div>
            <div class="feature">
              <h3>ROI/Recommendations</h3>
              <p>Detailed recommendations with cost-benefit analysis and implementation rationale</p>
            </div>
            <div class="feature">
              <h3>Progress Tracking</h3>
              <p>Real-time updates on implementation status</p>
            </div>
            <div class="feature">
              <h3>Resource Planning</h3>
              <p>Required team members and technical requirements</p>
            </div>
          </div>
          <button @click="navigateToSchedule" class="schedule-button">
            Schedule Discovery Call
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsContent',
  methods: {
    navigateToSchedule() {
      this.$emit('navigate', 'DiscoveryCallContent');
    }
  }
}
</script>

<style scoped>
.projects-content {
  height: calc(100vh - 4rem);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.projects-cta {
  flex: 1;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.cta-image {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.cta-content h2 {
  color: var(--accent-victory-green);
  font-size: 1.8rem;
  margin: 0;
}

.cta-content p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.4;
  margin: 0;
  text-align: center;
  max-width: 800px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;
  padding: 1.5rem;
  background: #f8f8f8;
  border-radius: 8px;
}

.feature {
  text-align: left;
  padding: 1rem;
}

.feature h3 {
  color: var(--accent-victory-green);
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.feature p {
  color: #666;
  font-size: 1rem;
  margin: 0;
  text-align: left;
}

.schedule-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.schedule-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

h1 {
  color: var(--accent-victory-green);
  margin: 0 0 1.5rem 0;
}
</style> 