import axios from 'axios';
// import firebase from '../firebase';

const API_BASE_URL = 'https://victoryai.pythonanywhere.com';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const loginUser = async (email, password, rememberMe) => {
  console.log('loginUser called with:', { email, rememberMe });
  try {
    console.log('Making API request to:', `${API_BASE_URL}/api/login`);
    const response = await api.post('/api/login', { 
      email, 
      password, 
      rememberMe 
    });
    
    console.log('Raw API response:', response);
    
    if (!response.data || !response.data.token) {
      throw new Error('Invalid login response - missing token');
    }

    if (!response.data.user_type) {
      throw new Error('Invalid login response - missing user type');
    }

    localStorage.setItem('authToken', response.data.token);
    localStorage.setItem('userType', response.data.user_type);
    localStorage.setItem('userEmail', email);
    
    if (rememberMe) {
      localStorage.setItem('rememberedEmail', email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }
    
    if (response.data.assessment) {
      console.log('Assessment data received:', response.data.assessment);
      localStorage.setItem('assessmentData', JSON.stringify({ assessment: response.data.assessment }));
    }

    return response.data;
  } catch (error) {
    console.error('Login error in api.js:', error);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error('Invalid email or password format');
        case 401:
          throw new Error('Invalid email or password');
        case 404:
          throw new Error('User not found');
        default:
          throw new Error(error.response.data.error || 'Login failed');
      }
    }
    throw error;
  }
};

export const signupUser = async (name, email, password, companyName) => {
  try {
    const response = await api.post('/api/signup', { 
      name, 
      email, 
      password,
      company_name: companyName
    });
    
    // Store the token and user type if signup is successful
    if (response.data.token) {
      localStorage.setItem('authToken', response.data.token);
      localStorage.setItem('userType', response.data.user_type);
    }
    
    // Log the assessment data
    if (response.data.assessment) {
      // console.log('Assessment data:', response.data.assessment);
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 409:
          throw new Error('An account with this email already exists');
        case 400:
          throw new Error('Name, email, and password are required');
        default:
          throw new Error(error.response.data.error || 'An error occurred during signup');
      }
    }
    throw new Error('An error occurred during signup');
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/api/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Error in forgot password:', error);
    throw new Error('Failed to process password reset request');
  }
};

// Add more API functions as needed

export const sendUserInfo = async (firstName, email) => {
  try {
    const response = await api.post('/start-assessment', { firstName, email });
    return response.data;
  } catch (error) {
    console.error('Error sending user info:', error);
    throw error;
  }
};

export const updateAssessmentResults = async (assessmentId, results) => {
  try {
    const response = await api.post('/update-assessment', { assessmentId, results });
    return response.data;
  } catch (error) {
    console.error('Error updating assessment results:', error);
    throw error;
  }
};

export async function completeAssessment(email, assessmentId, userChoices, firstName, isLoggedIn) {
  try {
    const response = await api.post('/api/complete-assessment', {
      email,
      assessment_id: assessmentId,
      user_choices: userChoices,
      firstName: firstName,
      is_logged_in: isLoggedIn
    });
    
    // Log the response data including the report URL
    console.log('Assessment completion response:', response.data);
    if (response.data.report_url) {
      // console.log('Report URL:', response.data.report_url);
    }
    
    return response.data;
  } catch (error) {
    console.error('Error completing assessment:', error);
    throw error;
  }
}

export const fetchLeads = async () => {
  try {
    const response = await api.get('/api/admin/leads');
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const fetchAssessment = async (assessmentId) => {
  try {
    const response = await api.get(`/api/assessment/${assessmentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assessment:', error);
    throw error;
  }
};

// Add these new functions for calendar operations
api.getAvailableTimes = async (date) => {
  try {
    const response = await api.get('/api/calendar/available-times', {
      params: { date: date.toISOString().split('T')[0] }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching available times:', error);
    throw error;
  }
};

api.bookAppointment = async (bookingData) => {
  try {
    const response = await api.post('/api/calendar/book', bookingData);
    return response.data;
  } catch (error) {
    console.error('Error booking appointment:', error);
    throw error;
  }
};

api.getMonthAvailability = async (year, month) => {
  try {
    const response = await api.get('/api/calendar/month-availability', {
      params: { year, month }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching month availability:', error);
    throw error;
  }
};

export const generateProposal = async (docId) => {
  try {
    const response = await api.post('/api/generate-proposal', { docId });
    return response.data;
  } catch (error) {
    console.error('Error generating proposal:', error);
    throw error;
  }
};

export const updateAnswers = async (docId, type, field, value) => {
  try {
    const response = await api.post('/api/update-answers', {
      docId,
      type,
      field,
      value
    });
    return response.data;
  } catch (error) {
    console.error('Error updating answers:', error);
    throw error;
  }
};

export const updateLeadStep = async (docId, step) => {
  try {
    const response = await api.post('/api/update-lead-step', {
      docId,
      step
    });
    return response.data;
  } catch (error) {
    console.error('Error updating lead step:', error);
    throw error;
  }
};

export const addContact = async (docId, email) => {
  try {
    const response = await api.post('/api/add-contact', {
      docId,
      email
    });
    return response.data;
  } catch (error) {
    console.error('Error adding contact:', error);
    throw error;
  }
};

export const removeContact = async (docId, email) => {
  try {
    const response = await api.post('/api/remove-contact', {
      docId,
      email
    });
    return response.data;
  } catch (error) {
    console.error('Error removing contact:', error);
    throw error;
  }
};

export const toggleLeadActive = async (docId, active) => {
  try {
    const response = await api.post('/api/toggle-lead-active', {
      docId,
      active
    });
    return response.data;
  } catch (error) {
    console.error('Error toggling lead active status:', error);
    throw error;
  }
};

export const uploadTranscript = async (transcriptText, leadEmail) => {
  try {
    const response = await api.post('/api/transcription', {
      meeting_transcription: transcriptText,
      participants: [leadEmail],
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading transcript:', error);
    throw error;
  }
};

export const sendProposalEmail = async (docId) => {
  try {
    const response = await api.post('/api/send-proposal-email', { docId });
    return response.data;
  } catch (error) {
    console.error('Error sending proposal email:', error);
    throw error;
  }
};

export const submitWhitePaperEmail = async (email) => {
  try {
    const response = await api.post('/api/white-paper', { email });
    return response.data;
  } catch (error) {
    console.error('Error submitting white paper email:', error);
    throw error;
  }
};

export const getCompletedAssessment = async (assessmentId) => {
  try {
    console.log('Making API request to get completed assessment:', assessmentId);
    const response = await api.get(`/api/get-completed-assessment/${assessmentId}`);
    console.log('API response for completed assessment:', response.data);
    
    // If the request is successful, update the assessment data in localStorage
    if (response.data.assessment) {
      console.log('Updating localStorage with new assessment data');
      localStorage.setItem('assessmentData', JSON.stringify({ 
        assessment: response.data.assessment 
      }));
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching completed assessment:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Assessment not found');
    }
    throw error;
  }
};

export const addStakeholder = async (docId, stakeholderData) => {
  try {
    const response = await api.post('/api/add-stakeholder', {
      doc_id: docId,
      ...stakeholderData
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 409:
          throw new Error('A stakeholder with this email already exists');
        case 404:
          throw new Error('Assessment not found');
        case 400:
          throw new Error('Please fill in all required fields');
        default:
          throw new Error('An error occurred while adding the stakeholder');
      }
    }
    throw error;
  }
};

export const removeStakeholder = async (docId, email) => {
  try {
    const response = await api.post('/api/remove-stakeholder', {
      doc_id: docId,
      email: email
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 404:
          throw new Error('Stakeholder or assessment not found');
        case 400:
          throw new Error('Missing required information');
        default:
          throw new Error('An error occurred while removing the stakeholder');
      }
    }
    throw error;
  }
};

export const submitSupportRequest = async (supportData) => {
  try {
    const response = await api.post('/api/submit-support', supportData);
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          throw new Error('Please fill in all required fields');
        default:
          throw new Error('An error occurred while submitting your request');
      }
    }
    throw error;
  }
};

// Add these new chat-related functions

export const sendChatMessage = async (message, messages = [], conversationId = null) => {
  try {
    const userId = localStorage.getItem('userEmail');
    const response = await api.post('/api/conversation', {
      message,
      messages,
      user_id: userId,
      conversation_id: conversationId
    });
    return response.data;
  } catch (error) {
    console.error('Error sending chat message:', error);
    throw error;
  }
};

export const getConversations = async () => {
  try {
    const userId = localStorage.getItem('userEmail');
    const response = await api.get('/api/conversations', {
      params: { user_id: userId || 'anonymous' }
    });
    return response.data.conversations;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};

export const getConversation = async (conversationId) => {
  try {
    const response = await api.get(`/api/conversations/${conversationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching conversation:', error);
    throw error;
  }
};

export const deleteConversation = async (conversationId) => {
  try {
    const userId = localStorage.getItem('userEmail');
    if (!userId) {
      throw new Error('User not authenticated');
    }

    const response = await api.delete(`/api/conversations/${conversationId}`, {
      params: { user_id: userId }
    });
    
    if (response.data.deleted_id !== conversationId) {
      throw new Error('Conversation deletion failed');
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          throw new Error('You are not authorized to delete this conversation');
        case 404:
          throw new Error('Conversation not found');
        case 400:
          throw new Error('Invalid request');
        default:
          throw new Error('Failed to delete conversation');
      }
    }
    throw error;
  }
};

export const deleteMessage = async (conversationId, messageIndex) => {
  try {
    const userId = localStorage.getItem('userEmail');
    if (!userId) {
      throw new Error('User not authenticated');
    }

    const response = await api.delete(
      `/api/conversations/${conversationId}/messages/${messageIndex}`,
      {
        params: { user_id: userId }
      }
    );
    
    // Filter out any null/undefined messages before returning
    if (response.data.messages) {
      response.data.messages = response.data.messages.filter(
        msg => msg.user_message || msg.ai_response
      );
    }
    
    return response.data;
  } catch (error) {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          throw new Error('You are not authorized to modify this conversation');
        case 404:
          throw new Error('Conversation not found');
        case 400:
          throw new Error('Invalid message index');
        default:
          throw new Error('Failed to delete message');
      }
    }
    throw error;
  }
};

export const submitVIPGiveaway = async (email, name, company, caseStudyPreference, selectedPackage) => {
  try {
    const response = await api.post('/api/vip-giveaway', { 
      email, 
      name, 
      company,
      selectedPackage,
      caseStudyPreference
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting VIP giveaway:', error);
    if (error.response) {
      if (error.response.status === 409) {
        throw new Error('You have already registered for the giveaway');
      }
      throw new Error(error.response.data.error || 'Failed to submit registration');
    }
    throw new Error('Failed to submit registration');
  }
};

export const getVIPPackageCounts = async () => {
  try {
    const response = await api.get('/api/vip-package-counts');
    console.log('Package counts response:', response.data);
    
    // If the response doesn't include 'department', set a default
    if (!response.data.hasOwnProperty('department')) {
      console.warn('No department count found in response, using default');
      return { department: 20 };  // Default to all packages available
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching VIP package counts:', error);
    // Return a default value instead of throwing
    return { department: 20 };  // Default to all packages available if request fails
  }
};

export default api;
