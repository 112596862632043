<template>
  <svg class="connection-line" :style="svgStyle">
    <path 
      :d="pathData" 
      fill="none" 
      :stroke="connectionColor" 
      stroke-width="2"
      :stroke-dasharray="type === 'no' ? '5,5' : 'none'"
      stroke-opacity="0.6"
    />
  </svg>
</template>

<script>
export default {
  name: 'NodeConnection',
  props: {
    startX: Number,
    startY: Number,
    endX: Number,
    endY: Number,
    type: {
      type: String,
      default: 'normal'  // can be 'normal', 'yes', or 'no'
    }
  },
  computed: {
    svgStyle() {
      const minX = Math.min(this.startX, this.endX) - 2 // Slight offset for stroke width
      const minY = Math.min(this.startY, this.endY) - 2
      const width = Math.abs(this.endX - this.startX) + 4
      const height = Math.abs(this.endY - this.startY) + 4
      
      return {
        position: 'absolute',
        left: `${minX}px`,
        top: `${minY}px`,
        width: `${width}px`,
        height: `${height}px`,
        overflow: 'visible'
      }
    },
    pathData() {
      const localStartX = this.startX < this.endX ? 0 : Math.abs(this.endX - this.startX)
      const localStartY = this.startY < this.endY ? 0 : Math.abs(this.endY - this.startY)
      const localEndX = this.startX < this.endX ? Math.abs(this.endX - this.startX) : 0
      const localEndY = this.startY < this.endY ? Math.abs(this.endY - this.startY) : 0
      
      return `M ${localStartX} ${localStartY} L ${localEndX} ${localEndY}`
    },
    connectionColor() {
      switch(this.type) {
        case 'yes':
          return '#4CAF50'  // Green for Yes
        case 'no':
          return '#FF5252'  // Red for No
        default:
          return 'white'    // Default white
      }
    }
  }
}
</script>

<style scoped>
.connection-line {
  pointer-events: none;
  z-index: 0;
  position: absolute;
}
</style> 