<template>
  <div class="support-content">
    <h1>Support</h1>
    <div class="content-container">
      <div class="support-card">
        <div class="support-info">
          <h2>How Can We Help?</h2>
          <p>
            Please describe any issues you're experiencing or questions you have. 
            Our team will respond within 24 business hours.
          </p>
          
          <div class="support-form">
            <div class="form-group">
              <label for="name">Name</label>
              <input 
                type="text" 
                id="name" 
                v-model="formData.name" 
                placeholder="Your name"
                :class="{ 'error': errors.name }"
              >
              <span class="error-message" v-if="errors.name">{{ errors.name }}</span>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input 
                type="email" 
                id="email" 
                v-model="formData.email" 
                placeholder="Your email"
                :class="{ 'error': errors.email }"
              >
              <span class="error-message" v-if="errors.email">{{ errors.email }}</span>
            </div>

            <div class="form-group">
              <label for="message">Message</label>
              <textarea 
                id="message" 
                v-model="formData.message" 
                placeholder="Please describe your issue or question"
                rows="6"
                :class="{ 'error': errors.message }"
              ></textarea>
              <span class="error-message" v-if="errors.message">{{ errors.message }}</span>
            </div>

            <button 
              @click="submitForm" 
              class="submit-button"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? 'Sending...' : 'Submit' }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Success/Error Modal -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content" :class="modalType">
        <div class="modal-icon">
          <div v-if="modalType === 'loading'" class="loading-spinner"></div>
          <span v-else-if="modalType === 'error'" class="error-icon">!</span>
          <span v-else-if="modalType === 'success'" class="success-icon">✓</span>
        </div>
        <p>{{ modalMessage }}</p>
        <button v-if="modalType !== 'loading'" @click="closeModal" class="modal-close">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { submitSupportRequest } from '../services/api'

export default {
  name: 'SupportContent',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      errors: {},
      isSubmitting: false,
      showModal: false,
      modalType: 'loading', // 'loading', 'success', or 'error'
      modalMessage: ''
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    // Try to get user info from Vuex store first
    if (this.user) {
      this.formData.name = this.user.name || ''
      this.formData.email = this.user.email || ''
    } else {
      // Fall back to localStorage if available
      const assessmentData = JSON.parse(localStorage.getItem('assessmentData') || '{}')
      if (assessmentData.assessment) {
        this.formData.name = assessmentData.assessment.name || ''
        this.formData.email = assessmentData.assessment.email || ''
      }
    }
  },
  methods: {
    validateForm() {
      this.errors = {}
      
      if (!this.formData.name.trim()) {
        this.errors.name = 'Name is required'
      }
      
      if (!this.formData.email.trim()) {
        this.errors.email = 'Email is required'
      } else if (!this.isValidEmail(this.formData.email)) {
        this.errors.email = 'Please enter a valid email'
      }
      
      if (!this.formData.message.trim()) {
        this.errors.message = 'Message is required'
      }
      
      return Object.keys(this.errors).length === 0
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    showLoadingModal() {
      this.modalType = 'loading';
      this.modalMessage = 'Submitting your request...';
      this.showModal = true;
    },

    showSuccessModal() {
      this.modalType = 'success';
      this.modalMessage = 'Your support request has been submitted. We will respond within 24 business hours.';
      this.showModal = true;
    },

    showErrorModal(message) {
      this.modalType = 'error';
      this.modalMessage = message;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.modalMessage = '';
    },

    async submitForm() {
      if (!this.validateForm()) return;
      
      this.showLoadingModal();
      
      try {
        await submitSupportRequest({
          name: this.formData.name,
          email: this.formData.email,
          message: this.formData.message
        });
        
        // Clear form after successful submission
        this.formData.message = '';
        this.showSuccessModal();
      } catch (error) {
        this.showErrorModal(
          error.message || 'There was an error submitting your request. Please try signing out and signing back in.'
        );
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.support-content {
  height: calc(100vh - 4rem);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.support-card {
  flex: 1;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.support-info {
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  color: var(--accent-victory-green);
  margin: 0 0 1.5rem 0;
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
}

p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.4;
  margin-bottom: 2rem;
}

.support-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  font-weight: 500;
  color: #333;
}

input, textarea {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--accent-victory-green);
}

input.error, textarea.error {
  border-color: #ff4444;
}

.error-message {
  color: #ff4444;
  font-size: 0.9rem;
}

.submit-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.submit-button:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modal-icon {
  margin-bottom: 1rem;
}

.error-icon, .success-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.error-icon {
  background-color: #ff4444;
  color: white;
}

.success-icon {
  background-color: var(--accent-victory-green);
  color: white;
}

.modal-content.loading {
  padding: 3rem;
}

.modal-content.loading .loading-spinner {
border: 4px solid #f3f3f3;
  border-top: 4px solid var(--accent-victory-green);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.modal-content p {
  margin: 1rem 0;
  color: #333;
  font-size: 1.1rem;
}

.modal-close {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.modal-close:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}
</style> 