<template>
  <infinite-canvas 
    ref="infiniteCanvas"
    @scale-change="handleScaleChange">
    <div v-for="swimlane in departmentSwimlanes" 
         :key="swimlane.id" 
         class="swimlane-container"
         @mousedown.left="startDrag"
         :style="swimlaneStyle">
      <h1>{{ swimlane.name }}</h1>
      
      <div class="swimlane-chart">
        <div class="departments-column" @mousedown.stop>
          <div v-for="(dept, index) in swimlane.swimDepartments" 
               :key="dept.id" 
               class="department"
               draggable="true"
               @dragstart="startDepartmentDrag(index, $event)"
               @dragover.prevent
               @dragenter.prevent="dragEnterDepartment(index)"
               @drop="dropDepartment(index)"
               :class="{ 'drag-over': dragOverIndex === index }"
               :style="getDepartmentStyle(dept)">
            <div class="department-header" 
                 :style="getDepartmentHeaderStyle(dept)">
              {{ dept.name }}
            </div>
            
            <div class="department-edge">
              <div class="hover-area"
                   @mouseenter="hoveredDepartment = index"
                   @mouseleave="hoveredDepartment = null">
              </div>
              <div v-show="hoveredDepartment === index" class="department-buttons">
                <button class="dept-btn remove-btn" 
                        @click.stop="removeDepartment(index)"
                        :disabled="swimlane.swimDepartments.length <= 1">
                  <span>-</span>
                </button>
                <button class="dept-btn add-btn" @click.stop="openDepartmentModal(index)">
                  <span>+</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-container">
          <node-connection
            v-for="conn in connections"
            :key="conn.id"
            :start-x="getConnectionStartX(conn)"
            :start-y="getConnectionStartY(conn)"
            :end-x="getConnectionEndX(conn)"
            :end-y="getConnectionEndY(conn)"
            :type="conn.type"
          />
          
          <div v-for="colIndex in numberOfColumns" :key="colIndex" class="grid-column">
            <div v-for="(dept, rowIndex) in swimlane.swimDepartments"
                 :key="`${colIndex}-${dept.id}`"
                 class="grid-cell"
                 :data-col="colIndex"
                 :data-row="rowIndex"
                 @mouseenter="hoveredCell = `${colIndex}-${rowIndex}`"
                 @mouseleave="hoveredCell = null">
              <div v-if="hoveredCell === `${colIndex}-${rowIndex}` && !isOccupied(colIndex, rowIndex)" 
                   class="add-node-hint"
                   @click="openNodeSelector(colIndex, rowIndex)">
                <span>+</span>
              </div>
            </div>
          </div>
          
          <template v-for="node in nodes" :key="node.id">
            <flow-node
              v-if="node.type !== 'decision'"
              :id="node.id"
              :title="node.title"
              :description="node.description"
              :position="node.position"
              :type="node.type"
              @update:description="updateNodeDescription(node.id, $event)"
              @nodeDrag="handleNodeDrag"
              @nodeDragEnd="handleNodeDragEnd"
              @openNodeSelector="handleNodeTypeSelector"
              @remove="handleRemoveNode"
            />
            <decision-node
              v-else
              :id="node.id"
              :content="node.title"
              :description="node.description"
              :position="node.position"
              @update:description="updateNodeDescription(node.id, $event)"
              @nodeDrag="handleNodeDrag"
              @nodeDragEnd="handleNodeDragEnd"
              @remove="handleRemoveNode(node.id)"
            />
          </template>
        </div>
      </div>
    </div>
  </infinite-canvas>

  <div v-if="showNodeSelector" class="node-selector-menu" :style="nodeSelectorPosition">
    <div class="node-selector-header">
      Select Node Type
    </div>
    <div class="node-selector-options">
      <button class="node-type-btn" @click="addNode('activity')">
        <span class="icon">⚙️</span>
        Activity
      </button>
      <button class="node-type-btn" @click="addNode('decision')">
        <span class="icon">❓</span>
        Decision
      </button>
    </div>
  </div>

  <department-modal
    v-if="showDepartmentModal"
    @close="showDepartmentModal = false"
    @submit="handleNewDepartment"
  />
</template>

<script>
import { reactive } from 'vue'
import { mapGetters } from 'vuex'
import InfiniteCanvas from '@/components/InfiniteCanvas.vue'
import FlowNode from '@/components/FlowNode.vue'
import NodeConnection from '@/components/NodeConnection.vue'
import DecisionNode from '@/components/DecisionNode.vue'
import DepartmentModal from '@/components/DepartmentModal.vue'

export default {
  name: 'SwimLane',
  components: {
    InfiniteCanvas,
    FlowNode,
    NodeConnection,
    DecisionNode,
    DepartmentModal
  },
  data() {
    // Grid measurements
    const cellWidth = 264
    const cellHeight = 250
    const horizontalGap = 100
    const columnWidth = cellWidth + horizontalGap
    const nodeWidth = 200
    const nodeHeight = 100

    // Calculate centered positions for initial nodes
    const getGridPosition = (col, row) => ({
      x: (col * columnWidth) + (cellWidth - nodeWidth) / 2,
      y: (row * cellHeight) + (cellHeight - nodeHeight) / 2
    })

    return {
      scale: 1,
      flowData: reactive({
        nodes: [
          { 
            id: 1, 
            title: 'Start', 
            position: getGridPosition(0, 0),
            type: 'activity'
          },
          { 
            id: 2, 
            title: 'Step 1', 
            position: getGridPosition(1, 0),
            type: 'activity'
          }
        ],
        connections: [
          { id: 1, source: 1, target: 2 }
        ]
      }),
      draggedNodeId: null,
      previousPosition: null,
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      hoveredDepartment: null,
      hoveredCell: null,
      showNodeSelector: false,
      selectedCell: null,
      nodeSelectorPosition: {
        top: '0px',
        left: '0px'
      },
      sourceNodeId: null,
      showDepartmentModal: false,
      selectedDepartmentIndex: null,
      draggedDeptIndex: null,
      dragOverIndex: null
    }
  },
  computed: {
    ...mapGetters('swimlane', [
      'getDepartmentSwimlanes',
    ]),
    
    departmentSwimlanes() {
      return this.getDepartmentSwimlanes('sales_dept')
    },

    nodes() {
      return this.flowData.nodes
    },

    connections() {
      return this.flowData.connections
    },

    gridWidth() {
      const cellWidth = 264
      const horizontalGap = 100
      return `${(cellWidth + horizontalGap) * this.numberOfColumns}px`
    },
    
    numberOfColumns() {
      const cellWidth = 264
      const horizontalGap = 100
      const columnWidth = cellWidth + horizontalGap
      
      const maxColumn = this.nodes.reduce((max, node) => {
        const nodeColumn = Math.floor(node.position.x / columnWidth)
        return Math.max(max, nodeColumn)
      }, 0)
      
      // Keep the minimum of 3 columns
      return Math.max(maxColumn + 1, 3)
    },
    
    swimlaneStyle() {
      return {
        transform: `translate(${this.position.x}px, ${this.position.y}px)`,
        cursor: this.isDragging ? 'grabbing' : 'grab'
      }
    },
    
    chartHeight() {
      const swimlane = this.departmentSwimlanes[0]
      if (!swimlane) return '1000px'
      const rowCount = swimlane.swimDepartments.length
      return `${rowCount * 250}px`
    },
    
    containerHeight() {
      const swimlane = this.departmentSwimlanes[0]
      if (!swimlane) return '1100px'
      const rowCount = swimlane.swimDepartments.length
      return `${(rowCount * 250) + 100}px`  // Add extra height for padding and title
    }
  },
  methods: {
    handleScaleChange(scale) {
      this.scale = scale
    },
    
    getDepartmentRow(department) {
      const swimlane = this.departmentSwimlanes[0]
      return swimlane.swimDepartments.findIndex(dept => dept.id === department) + 1
    },
    
    getNodeById(id) {
      return this.flowData.nodes.find(node => node.id === id)
    },
    
    handleNodeDragStart(event) {
      const node = this.flowData.nodes.find(n => n.id === this.draggedNodeId)
      if (node) {
        this.previousPosition = { ...node.position }
      }
    },
    
    handleNodeDrag({ id, position }) {
      // During drag, just follow the cursor exactly
      const nodeIndex = this.flowData.nodes.findIndex(n => n.id === id)
      if (nodeIndex !== -1) {
        // Store the previous position before the first move
        if (!this.previousPosition) {
          this.previousPosition = { ...this.flowData.nodes[nodeIndex].position }
        }
        this.flowData.nodes[nodeIndex].position = position
      }
    },
    
    handleNodeDragEnd({ id, event }) {
      // Grid cell dimensions
      const cellWidth = 264
      const cellHeight = 250
      const horizontalGap = 100
      const columnWidth = cellWidth + horizontalGap
      const nodeWidth = 200
      const nodeHeight = 100
      
      // Get current node position
      const node = this.flowData.nodes.find(n => n.id === id)
      if (!node) return
      
      // Calculate the nearest grid position
      const col = Math.round(node.position.x / columnWidth)
      const row = Math.round(node.position.y / cellHeight)
      
      // Calculate snapped position
      const snappedX = col * columnWidth + (cellWidth - nodeWidth) / 2
      const snappedY = row * cellHeight + (cellHeight - nodeHeight) / 2
      
      // Check if any other node occupies this position
      const isOccupied = this.flowData.nodes.some(otherNode => {
        if (otherNode.id === id) return false // Skip the current node
        return otherNode.position.x === snappedX && otherNode.position.y === snappedY
      })
      
      // Update node position - if occupied, return to previous position
      const nodeIndex = this.flowData.nodes.findIndex(n => n.id === id)
      if (nodeIndex !== -1) {
        this.flowData.nodes[nodeIndex].position = isOccupied 
          ? this.previousPosition                // Return to previous grid position
          : { x: snappedX, y: snappedY }        // Snap to new position
      }
      
      // Reset previousPosition after drag ends
      this.previousPosition = null
    },
    
    handleAddNode({ sourceId, position }) {
      // Generate a new unique ID
      const newId = Math.max(...this.nodes.map(n => n.id)) + 1
      
      // Add the new node
      this.flowData.nodes.push({
        id: newId,
        title: `Node ${newId}`,
        position: position
      })
      
      // Add the connection
      this.flowData.connections.push({
        id: Math.max(...this.connections.map(c => c.id)) + 1,
        source: sourceId,
        target: newId
      })
    },
    
    handleRemoveNode(nodeId) {
      // Get the node being removed
      const nodeToRemove = this.getNodeById(nodeId)

      if (nodeToRemove.type === 'decision') {
        // Find all connections from this decision node
        const connectedNodes = this.flowData.connections
          .filter(conn => conn.source === nodeId)
          .map(conn => conn.target)

        // Remove the Yes/No nodes
        this.flowData.nodes = this.flowData.nodes.filter(
          node => !connectedNodes.includes(node.id)
        )

        // Remove all connections involving the decision node and its Yes/No nodes
        this.flowData.connections = this.flowData.connections.filter(
          conn => !connectedNodes.includes(conn.target) && 
                 !connectedNodes.includes(conn.source) && 
                 conn.source !== nodeId && 
                 conn.target !== nodeId
        )
      } else {
        // Handle regular node removal
        this.flowData.connections = this.flowData.connections.filter(
          conn => conn.source !== nodeId && conn.target !== nodeId
        )
      }

      // Remove the node itself
      this.flowData.nodes = this.flowData.nodes.filter(
        node => node.id !== nodeId
      )
    },
    
    startDrag(event) {
      // Don't start drag if clicking on a node or departments column
      if (event.target.closest('.flow-node') || event.target.closest('.departments-column')) return
      
      this.isDragging = true
      this.dragStart = {
        x: event.clientX,
        y: event.clientY,
        startPositionX: this.position.x,
        startPositionY: this.position.y
      }
      
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.stopDrag)
      
      event.preventDefault()
    },

    handleDrag(event) {
      if (!this.isDragging) return
      
      // Use a base multiplier of 2.0
      const baseMultiplier = 2.0
      const scaleAdjustedMultiplier = baseMultiplier * Math.sqrt(1 / this.scale)
      
      const deltaX = event.clientX - this.dragStart.x
      const deltaY = event.clientY - this.dragStart.y
      
      this.position = {
        x: this.dragStart.startPositionX + (deltaX * scaleAdjustedMultiplier),
        y: this.dragStart.startPositionY + (deltaY * scaleAdjustedMultiplier)
      }
      
      event.preventDefault()
    },

    stopDrag() {
      if (this.isDragging) {
        this.isDragging = false
        // Remove window event listeners
        window.removeEventListener('mousemove', this.handleDrag)
        window.removeEventListener('mouseup', this.stopDrag)
      }
    },

    getDepartmentStyle(dept) {
      return {
        backgroundColor: dept.color,
        height: '250px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },

    getDepartmentHeaderStyle(dept) {
      return {
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
        textTransform: 'uppercase',
        fontWeight: '600',
        letterSpacing: '1px',
        color: dept.isLightTheme ? 'var(--main-text-color)' : 'white',
        textShadow: dept.isLightTheme ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.3)',
        fontSize: '14px'
      }
    },
    
    addDepartment(index) {
      const newDept = {
        id: `dept_${Date.now()}`,
        name: `DEPARTMENT ${this.swimlane.swimDepartments.length + 1}`,
        isLightTheme: false
      }
      this.$store.dispatch('swimlane/addDepartment', {
        swimlaneId: this.swimlane.id,
        department: newDept,
        index: index + 1
      })
    },
    
    removeDepartment(index) {
      const swimlane = this.departmentSwimlanes[0]  // Get the current swimlane
      if (swimlane && swimlane.swimDepartments.length > 1) {  // Prevent removing last department
        // First, remove any nodes in this row
        const cellHeight = 250
        const nodesToRemove = []
        
        // Find nodes in the row being removed
        this.flowData.nodes.forEach(node => {
          const nodeRow = Math.floor(node.position.y / cellHeight)
          if (nodeRow === index) {
            nodesToRemove.push(node.id)
          }
        })
        
        // Remove the nodes
        nodesToRemove.forEach(nodeId => {
          // Remove the node
          this.flowData.nodes = this.flowData.nodes.filter(node => node.id !== nodeId)
          // Remove any connections involving this node
          this.flowData.connections = this.flowData.connections.filter(
            conn => conn.source !== nodeId && conn.target !== nodeId
          )
        })
        
        // Update positions of remaining nodes
        this.flowData.nodes.forEach(node => {
          const nodeRow = Math.floor(node.position.y / cellHeight)
          if (nodeRow > index) {
            node.position.y -= cellHeight // Move up one row
          }
        })
        
        // Finally, remove the department
        this.$store.dispatch('swimlane/removeDepartment', {
          swimlaneId: swimlane.id,
          index
        })
      }
    },
    
    handleDepartmentHover(index) {
      this.hoveredDepartment = index;
    },
    
    handleDepartmentLeave() {
      this.hoveredDepartment = null;
    },
    
    isOccupied(col, row) {
      const cellWidth = 264
      const cellHeight = 250
      const horizontalGap = 100
      const nodeWidth = 200
      const nodeHeight = 100
      
      // Calculate cell center position for the current grid cell
      const adjustedCol = col - 1  // Adjust for 1-based column indexing
      const position = {
        x: adjustedCol * (cellWidth + horizontalGap) + ((cellWidth - nodeWidth) / 2),
        y: row * cellHeight + ((cellHeight - nodeHeight) / 2)
      }
      
      // Check if any node occupies this exact position
      return this.flowData.nodes.some(node => 
        Math.abs(node.position.x - position.x) < 1 && 
        Math.abs(node.position.y - position.y) < 1
      )
    },

    openNodeSelector(col, row) {
      this.selectedCell = { col, row }
      this.showNodeSelector = true
      
      // Position the menu near the clicked cell
      const cell = document.querySelector(`.grid-cell[data-col="${col}"][data-row="${row}"]`)
      if (cell) {
        const rect = cell.getBoundingClientRect()
        this.nodeSelectorPosition = {
          top: `${rect.top + window.scrollY + rect.height/2}px`,
          left: `${rect.left + window.scrollX + rect.width/2}px`
        }
      }
    },

    handleNodeTypeSelector({ sourceId, position }) {
      this.sourceNodeId = sourceId  // Store source node ID
      this.showNodeSelector = true
      this.nodeSelectorPosition = {
        top: `${position.y}px`,
        left: `${position.x}px`
      }
    },

    addNode(type) {
      if (this.selectedCell || this.sourceNodeId) {
        // Calculate base position based on whether it's from grid or connection
        let basePosition
        if (this.selectedCell) {
          const { col, row } = this.selectedCell
          const cellWidth = 264
          const cellHeight = 250
          const horizontalGap = 100
          const nodeWidth = 200
          const nodeHeight = 100
          const adjustedCol = col - 1

          basePosition = {
            x: adjustedCol * (cellWidth + horizontalGap) + ((cellWidth - nodeWidth) / 2),
            y: row * cellHeight + ((cellHeight - nodeHeight) / 2)
          }
        } else {
          const sourceNode = this.getNodeById(this.sourceNodeId)
          const columnWidth = 264 + 100
          basePosition = {
            x: sourceNode.position.x + columnWidth,
            y: sourceNode.position.y
          }
        }

        // Generate new IDs
        const newId = Math.max(...this.nodes.map(n => n.id)) + 1

        // Determine node title based on type and position
        let nodeTitle
        if (newId === 1) {
          nodeTitle = 'Start'
        } else if (type === 'decision') {
          nodeTitle = `Decision ${newId}`
        } else {
          nodeTitle = `Step ${newId - 1}`  // Subtract 1 since Step 1 should be node 2
        }

        // Add the main node
        this.flowData.nodes.push({
          id: newId,
          title: nodeTitle,
          position: basePosition,
          type: type
        })

        // If it's a decision node, add Yes/No nodes with appropriate titles
        if (type === 'decision') {
          const cellHeight = 250
          const cellWidth = 264
          const horizontalGap = 100
          
          // Get the current row number and total rows
          const currentRow = Math.floor(basePosition.y / cellHeight)
          const totalRows = this.departmentSwimlanes[0].swimDepartments.length
          const lastRow = totalRows - 1
          
          // Add "Yes" node (handle row 0 edge case)
          const yesId = newId + 1
          this.flowData.nodes.push({
            id: yesId,
            title: 'Yes',
            position: {
              x: basePosition.x + (cellWidth + horizontalGap),
              // If decision node is in row 0, keep Yes node in same row
              y: currentRow === 0 ? basePosition.y : basePosition.y - cellHeight
            },
            type: 'activity'
          })

          // Add "No" node (handle bottom row edge case)
          const noId = newId + 2
          this.flowData.nodes.push({
            id: noId,
            title: 'No',
            position: {
              x: basePosition.x + (cellWidth + horizontalGap),
              // If decision node is in last row, keep No node in same row
              y: currentRow === lastRow ? basePosition.y : basePosition.y + cellHeight
            },
            type: 'activity'
          })

          // Add connections
          this.flowData.connections.push(
            {
              id: Math.max(...this.connections.map(c => c.id)) + 1,
              source: newId,
              target: yesId,
              type: 'yes'
            },
            {
              id: Math.max(...this.connections.map(c => c.id)) + 2,
              source: newId,
              target: noId,
              type: 'no'
            }
          )
        }

        // Add connection from source node if this was created from a node
        if (this.sourceNodeId) {
          this.flowData.connections.push({
            id: Math.max(...this.connections.map(c => c.id)) + 1,
            source: this.sourceNodeId,
            target: newId
          })
        }
      }

      // Reset state
      this.showNodeSelector = false
      this.selectedCell = null
      this.sourceNodeId = null
    },

    getConnectionStartX(connection) {
      const sourceNode = this.getNodeById(connection.source)
      if (sourceNode.type === 'decision') {
        if (connection.type === 'yes') {
          return sourceNode.position.x + 160  // Full width of diamond
        } else if (connection.type === 'no') {
          return sourceNode.position.x + 160  // Full width of diamond
        }
      }
      return sourceNode.position.x + 200
    },

    getConnectionStartY(connection) {
      const sourceNode = this.getNodeById(connection.source)
      if (sourceNode.type === 'decision') {
        if (connection.type === 'yes') {
          return sourceNode.position.y + 5
        } else if (connection.type === 'no') {
          return sourceNode.position.y + 90
        }
      }
      return sourceNode.position.y + 50
    },

    getConnectionEndX(connection) {
      const targetNode = this.getNodeById(connection.target)
      if (targetNode.type === 'decision') {
        return targetNode.position.x + 20  // Offset slightly to hit the left vertex
      }
      return targetNode.position.x
    },

    getConnectionEndY(connection) {
      const targetNode = this.getNodeById(connection.target)
      if (targetNode.type === 'decision') {
        return targetNode.position.y + 50
      }
      return targetNode.position.y + 50
    },

    openDepartmentModal(index) {
      this.selectedDepartmentIndex = index
      this.showDepartmentModal = true
    },

    handleNewDepartment(name) {
      const swimlane = this.departmentSwimlanes[0]  // Get the current swimlane
      if (!swimlane) return

      const newDept = {
        id: `dept_${Date.now()}`,
        name: name.toUpperCase(),
        isLightTheme: false
      }
      
      this.$store.dispatch('swimlane/addDepartment', {
        swimlaneId: swimlane.id,
        department: newDept,
        index: this.selectedDepartmentIndex + 1
      })
      
      this.showDepartmentModal = false
      this.selectedDepartmentIndex = null
    },

    startDepartmentDrag(index, event) {
      this.draggedDeptIndex = index
      event.dataTransfer.effectAllowed = 'move'
    },

    dragEnterDepartment(index) {
      if (index !== this.draggedDeptIndex) {
        this.dragOverIndex = index
      }
    },

    async dropDepartment(dropIndex) {
      if (this.draggedDeptIndex === null || dropIndex === this.draggedDeptIndex) {
        this.dragOverIndex = null
        return
      }

      const swimlane = this.departmentSwimlanes[0]
      if (!swimlane) return

      // Get the department being moved
      const dept = swimlane.swimDepartments[this.draggedDeptIndex]

      // Remove from old position and insert at new position
      await this.$store.dispatch('swimlane/reorderDepartments', {
        swimlaneId: swimlane.id,
        fromIndex: this.draggedDeptIndex,
        toIndex: dropIndex
      })

      // Reset drag state
      this.draggedDeptIndex = null
      this.dragOverIndex = null
    },

    updateNodeDescription(nodeId, description) {
      const node = this.flowData.nodes.find(n => n.id === nodeId)
      if (node) {
        node.description = description
      }
    }
  },
  created() {
    this.$store.dispatch('swimlane/initializeExampleData')
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.stopDrag)
  }
}
</script>

<style scoped>
.swimlane-container {
  min-height: v-bind('containerHeight');
  height: auto;
  padding: 2rem 4rem 4rem 4rem;
  background-color: var(--primary-background-cream);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin: 2rem;
  overflow: visible;
  position: relative;
  user-select: none;
  touch-action: none;
  transform-origin: 0 0;
  will-change: transform;
  transition: none;
}

h1 {
  color: var(--main-text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.swimlane-chart {
  background-color: var(--accent-victory-green);
  border-radius: 8px;
  padding: 0;
  display: flex;
  min-height: v-bind('chartHeight');
  height: auto;
  overflow: visible;
  position: relative;
  min-width: fit-content;
  width: 100%;
}

.departments-column {
  width: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: v-bind('chartHeight');
  gap: 0;
  margin-right: 50px;
  position: relative;
  z-index: 1000;
  pointer-events: all;
}

.department {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: grab;
  transition: transform 0.2s, box-shadow 0.2s;
}

.department:active {
  cursor: grabbing;
}

.department.drag-over {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.department-header {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-transform: uppercase;
}

.department-edge {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  pointer-events: auto;
}

.hover-area {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  bottom: 0;
  transform: translateY(50%);
  cursor: pointer;
  pointer-events: auto;
}

.department-buttons {
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px;
  border-radius: 4px;
  z-index: 1001;
  pointer-events: auto;
}

.hover-area:hover + .department-buttons,
.department-buttons:hover {
  display: flex !important;
}

.dept-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color 0.2s;
}

.dept-btn.add-btn {
  background-color: var(--accent-victory-green);
}

.dept-btn.add-btn:hover {
  background-color: #0D4E36;
}

.dept-btn.remove-btn {
  background-color: #dc3545;
}

.dept-btn.remove-btn:hover {
  background-color: #bd2130;
}

.dept-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.grid-container {
  display: flex;
  flex-grow: 0;
  height: v-bind('chartHeight');
  gap: 0 100px;
  position: relative;
  z-index: 1;
  width: v-bind('gridWidth');
  min-width: fit-content;
  pointer-events: auto;
  cursor: default;
}

.grid-column {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  gap: 0;
  width: 264px;
  flex: 0 0 264px;
  position: relative;
  z-index: 2;
}

.grid-cell {
  height: 250px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  transition: background-color 0.2s;
  position: relative;
}

.grid-cell:last-child {
  border-bottom: none;
}

.grid-column:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.grid-cell.drag-over {
  background-color: rgba(255, 255, 255, 0.1);
}

.add-node-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--accent-victory-green);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0.6;
  transition: opacity 0.2s;
  cursor: pointer;
}

.add-node-hint:hover {
  opacity: 1;
}

.node-selector-menu {
  position: fixed;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 200px;
  overflow: hidden;
}

.node-selector-header {
  padding: 12px 16px;
  background: var(--accent-victory-green);
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.node-selector-options {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.node-type-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
  color: var(--accent-victory-green);
}

.node-type-btn:hover {
  background: var(--primary-background-cream);
}

.node-type-btn .icon {
  font-size: 18px;
}

</style> 