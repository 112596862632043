<template>
  <div class="node-container" 
       :style="nodeStyle"
       @mousedown.prevent.stop="startDrag"
       @mouseenter="isHovered = true"
       @mouseleave="isHovered = false">
    <div class="node decision">
      <div class="node-content">
        <div class="node-title">{{ content }}</div>
        <div class="node-description" @click.stop="startEditing" v-if="!isEditing">
          {{ description || 'Task description goes here...' }}
        </div>
        <input
          v-else
          ref="descriptionInput"
          v-model="editedDescription"
          class="description-input"
          @blur="saveDescription"
          @keyup.enter="saveDescription"
          @keyup.esc="cancelEdit"
        />
        <div class="node-icon">
          <i class="fas fa-question"></i>
        </div>
      </div>

      <!-- Connection points -->
      <div class="connection-point entry-point"></div>
      <div class="connection-point yes-point"></div>
      <div class="connection-point no-point"></div>

      <!-- Yes/No labels -->
      <div class="yes-label">Yes</div>
      <div class="no-label">No</div>

      <!-- Controls with hover area -->
      <div class="node-controls-wrapper" v-show="isHovered">
        <div class="node-controls">
          <button class="control-btn delete-btn" @click.stop="$emit('remove')">
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nodeDragMixin from '@/mixins/nodeDragMixin'

export default {
  mixins: [nodeDragMixin],
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    content: {
      type: String,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isEditing: false,
      editedDescription: ''
    }
  },
  computed: {
    nodeStyle() {
      const nodeWidth = 160
      const nodeHeight = 160

      const xOffset = 110 - (nodeWidth / 2)
      const yOffset = 50 - (nodeHeight / 2)

      return {
        position: 'absolute',
        transform: `translate(${this.position.x + xOffset}px, ${this.position.y + yOffset}px)`
      }
    }
  },
  methods: {
    startEditing() {
      this.isEditing = true
      this.editedDescription = this.description || 'Task description goes here...'
      this.$nextTick(() => {
        this.$refs.descriptionInput.focus()
        this.$refs.descriptionInput.select()
      })
    },
    
    saveDescription() {
      this.isEditing = false
      const newDescription = this.editedDescription.trim()
      if (newDescription !== this.description) {
        this.$emit('update:description', newDescription)
      }
    },
    
    cancelEdit() {
      this.isEditing = false
      this.editedDescription = this.description
    },
    handleClickOutside(event) {
      if (this.isEditing && !this.$el.contains(event.target)) {
        this.saveDescription()
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped>
.node-container {
  position: absolute;
  user-select: none;
  z-index: 3;
}

.decision {
  width: 160px;
  height: 160px;
  background: white;
  transform: rotate(45deg);
  position: relative;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.node-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 140px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Connection points */
.connection-point {
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--accent-victory-green);
  border: 2px solid white;
  border-radius: 50%;
  z-index: 2;
}

.entry-point {
  top: 90%;
  left: 0;

  /* right: 20px; */
}

.yes-point {
  right: 50%;
  top: 0;
  transform: translate(50%, -50%) rotate(-45deg);
}

.no-point {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

.yes-label {
  position: absolute;
  right: 50%;
  top: 0;
  transform: translate(150%, -100%) rotate(-45deg);
  color: white;
  font-size: 12px;
  background: rgba(9, 57, 39, 0.8);
  padding: 2px 8px;
  border-radius: 4px;
  transform-origin: left center;
  white-space: nowrap;
}

.no-label {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(150%, -50%) rotate(-45deg);
  color: white;
  font-size: 12px;
  background: rgba(9, 57, 39, 0.8);
  padding: 2px 8px;
  border-radius: 4px;
  transform-origin: left center;
  white-space: nowrap;
}

.node-controls-wrapper {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: 4;
  transform-origin: center;
  transform: translate(10px, 50px) rotate(-45deg);
  padding: 20px;
  margin: -20px;
}

.node-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.delete-btn:hover {
  transform: scale(1.1);
  background-color: #c82333;
}

.delete-btn i {
  font-size: 16px;
}

.node-icon {
  color: var(--accent-victory-green);
  font-size: 20px;
}

.node-description {
  font-size: 12px;
  color: #666;
  text-align: center;
  line-height: 1.4;
  margin: 4px auto;
  max-width: 120px;
  word-wrap: break-word;
  padding: 0 4px;
}

.description-input {
  font-size: 12px;
  color: #666;
  text-align: center;
  line-height: 1.4;
  margin: 4px auto;
  padding: 2px 4px;
  border: 1px solid var(--accent-victory-green);
  border-radius: 4px;
  width: 120px;
  max-width: 120px;
  outline: none;
  background: white;
}

.description-input:focus {
  box-shadow: 0 0 0 2px rgba(9, 57, 39, 0.1);
}
</style> 