<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <h2>New Department</h2>
      <div class="input-group">
        <label for="departmentName">Department Name:</label>
        <input 
          id="departmentName"
          v-model="departmentName"
          type="text"
          placeholder="Enter department name"
          @keyup.enter="handleSubmit"
          ref="nameInput"
        />
      </div>
      <div class="modal-actions">
        <button class="cancel-btn" @click="$emit('close')">Cancel</button>
        <button class="submit-btn" @click="handleSubmit" :disabled="!departmentName.trim()">
          Add Department
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepartmentModal',
  data() {
    return {
      departmentName: ''
    }
  },
  mounted() {
    this.$refs.nameInput.focus()
  },
  methods: {
    handleSubmit() {
      if (this.departmentName.trim()) {
        this.$emit('submit', this.departmentName.trim())
        this.departmentName = ''
      }
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

h2 {
  color: var(--main-text-color);
  margin: 0 0 20px 0;
  font-size: 18px;
}

.input-group {
  margin-bottom: 24px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: var(--main-text-color);
  font-size: 14px;
}

input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

input:focus {
  outline: none;
  border-color: var(--accent-victory-green);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn {
  background: #f0f0f0;
  color: var(--main-text-color);
}

.cancel-btn:hover {
  background: #e0e0e0;
}

.submit-btn {
  background: var(--accent-victory-green);
  color: white;
}

.submit-btn:hover {
  background: #0D4E36;
}

.submit-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style> 