export default {
  data() {
    return {
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      dragStartTime: 0,
      dragStartPos: { x: 0, y: 0 },
      isHovered: false
    }
  },

  methods: {
    startDrag(event) {
      event.preventDefault()
      event.stopPropagation()
      
      if (event.button !== 0) return // Only left mouse button
      
      this.dragStartTime = Date.now()
      this.dragStartPos = {
        x: event.clientX,
        y: event.clientY
      }
      
      this.isDragging = true
      const scale = this.$parent.scale || 1
      
      const gridContainer = this.$el.closest('.grid-container')
      const gridRect = gridContainer.getBoundingClientRect()
      
      this.dragOffset = {
        x: event.clientX - gridRect.left - (this.position.x * scale),
        y: event.clientY - gridRect.top - (this.position.y * scale)
      }
      
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.endDrag)
    },
    
    handleDrag(event) {
      if (!this.isDragging) return
      
      event.preventDefault()
      event.stopPropagation()
      
      const distance = Math.sqrt(
        Math.pow(event.clientX - this.dragStartPos.x, 2) +
        Math.pow(event.clientY - this.dragStartPos.y, 2)
      )
      
      if (distance < 5) return
      
      const scale = this.$parent.scale || 1
      const gridContainer = this.$el.closest('.grid-container')
      const gridRect = gridContainer.getBoundingClientRect()
      
      const newPosition = {
        x: (event.clientX - gridRect.left - this.dragOffset.x) / scale,
        y: (event.clientY - gridRect.top - this.dragOffset.y) / scale
      }
      
      this.$emit('nodeDrag', {
        id: this.id,
        position: newPosition,
        event
      })
    },
    
    endDrag(event) {
      if (!this.isDragging) return
      
      event.preventDefault()
      event.stopPropagation()
      
      const duration = Date.now() - this.dragStartTime
      const distance = Math.sqrt(
        Math.pow(event.clientX - this.dragStartPos.x, 2) +
        Math.pow(event.clientY - this.dragStartPos.y, 2)
      )
      
      if (duration < 200 && distance < 5) {
        this.isDragging = false
        window.removeEventListener('mousemove', this.handleDrag)
        window.removeEventListener('mouseup', this.endDrag)
        return
      }
      
      this.isDragging = false
      window.removeEventListener('mousemove', this.handleDrag)
      window.removeEventListener('mouseup', this.endDrag)
      
      this.$emit('nodeDragEnd', {
        id: this.id,
        event
      })
    }
  },

  mounted() {
    const node = this.$el.querySelector('.node')
    if (node) {
      node.addEventListener('mouseenter', () => this.isHovered = true)
      node.addEventListener('mouseleave', () => this.isHovered = false)
    }
  },

  beforeDestroy() {
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.endDrag)
    
    const node = this.$el.querySelector('.node')
    if (node) {
      node.removeEventListener('mouseenter', () => this.isHovered = true)
      node.removeEventListener('mouseleave', () => this.isHovered = false)
    }
  }
} 