import { createStore } from 'vuex'
import swimlane from './modules/swimlane'

export default createStore({
  state: {
    activeColumnIndex: 0,
    columnColors: [
      'var(--accent-victory-green)',
      'var(--accent-sunny-yellow)',
      'var(--secondary-background-sky-blue)',
      'white'
    ],
    contentData: [
      {
        title: "Why Choose Us",
        content: "We offer years of experience in AI implementation, customized solutions for your unique needs, and a proven track record of success. Our team of experts is dedicated to helping your business thrive with cutting-edge AI technology."
      },
      {
        title: "Our Expertise",
        content: "With a deep understanding of various AI technologies, we specialize in machine learning, natural language processing, and computer vision. Our solutions are tailored to meet the specific challenges of your industry."
      },
      {
        title: "Client Success Stories",
        content: "We've helped numerous businesses across different sectors achieve remarkable results. From increasing operational efficiency to developing innovative products, our AI solutions have consistently delivered tangible value."
      },
      {
        title: "Future-Proof Solutions",
        content: "As AI continues to evolve, we stay at the forefront of technological advancements. By choosing us, you're not just implementing AI; you're future-proofing your business for the challenges and opportunities of tomorrow."
      }
    ],
    user: {
      name: '',
      // ... other user properties
    },
    firstName: '',
    email: '',
    assessmentScore: 0,
    answers: [],
    userChoices: new Map(),
    assessment_id: null,
    showHeader: true,
    currentRoute: null,
    whitePaperBannerClosed: false,
    currentSection: null,
    loggedIn: false,
    chats: {
      history: [],
      currentChat: null
    }
  },
  getters: {
    isColumnVisible: (state) => (index) => {
      return index <= state.activeColumnIndex
    },
    isLastColumn: (state) => {
      return state.activeColumnIndex === state.columnColors.length - 1
    },
    totalScore: state => state.assessmentScore,
    allAnswers: state => state.answers,
    userChoices: state => {
      const choices = new Map();
      state.userChoices.forEach((value, key) => {
        choices.set(Number(key), value);
      });
      return choices;
    },
    assessmentId: state => state.assessment_id,
    showHeader: state => state.showHeader,
    isLoggedIn: state => state.loggedIn
  },
  mutations: {
    incrementActiveColumn(state) {
      if (state.activeColumnIndex < state.columnColors.length - 1) {
        state.activeColumnIndex++;
        // console.log('Active column incremented to:', state.activeColumnIndex);
      }
    },
    setActiveColumn(state, index) {
      if (index >= 0 && index < state.columnColors.length) {
        state.activeColumnIndex = index;
        // console.log('Active column set to:', state.activeColumnIndex);
      }
    },
    SET_USER(state, user) {
      state.user = user
    },
    CLEAR_USER(state) {
      state.user = {
        name: '',
        // ... reset other user properties
      }
    },
    ADD_POINTS(state, points) {
      state.assessmentScore += points
    },
    RESET_SCORE(state) {
      state.assessmentScore = 0
    },
    ADD_ANSWER(state, { answer, choice }) {
      state.answers.push(answer)
      state.userChoices.set(state.answers.length - 1, choice);
    },
    REMOVE_LAST_ANSWER(state) {
      state.answers.pop()
      state.userChoices.delete(state.answers.length - 1);
      state.assessmentScore -= state.answers[state.answers.length - 1] || 0
    },
    RESET_ANSWERS(state) {
      state.answers = [];
      state.userChoices.clear();
      state.assessmentScore = 0;
    },
    SET_USER_CHOICE(state, { index, choice }) {
      const numericIndex = Number(index);
      state.userChoices.set(numericIndex, choice);
      // console.log('Setting choice in store:', numericIndex, choice);
      // console.log('Updated choices:', Object.fromEntries(state.userChoices));
    },
    CLEAR_STORE(state) {
      state.user = null;
      state.assessmentScore = 0;
      state.answers = [];
      state.userChoices = new Map();
      state.assessment_id = null;
      state.showHeader = true;
    },
    SET_ASSESSMENT_ID(state, id) {
      state.assessment_id = id;
    },
    SET_USER_CHOICES(state, choices) {
      state.userChoices.clear();
      Object.entries(choices).forEach(([key, value]) => {
        if (value !== null) {
          const numericKey = Number(key);
          state.userChoices.set(numericKey, value);
        }
      });
      // console.log('Set user choices in store:', Object.fromEntries(state.userChoices));
    },
    SET_ANSWERS(state, answers) {
      state.answers = answers
    },
    SET_SCORE(state, score) {
      state.assessmentScore = score
    },
    SET_SHOW_HEADER(state, value) {
      state.showHeader = value
    },
    SET_CURRENT_ROUTE(state, route) {
      state.currentRoute = route
    },
    setUser(state, user) {
      state.user = user
    },
    setFirstName(state, firstName) {
      state.firstName = firstName
    },
    setEmail(state, email) {
      state.email = email
    },
    SET_WHITEPAPER_BANNER_CLOSED(state, value) {
      state.whitePaperBannerClosed = value
    },
    SET_CURRENT_SECTION(state, sectionName) {
      state.currentSection = sectionName
    },
    SET_LOGIN_STATE(state, value) {
      state.loggedIn = value;
    },
    SET_CHAT_HISTORY(state, history) {
      state.chats.history = history;
    },
    SET_CURRENT_CHAT(state, chat) {
      state.chats.currentChat = chat;
    },
    UPDATE_CHAT_HISTORY(state, { messages, context }) {
      if (state.chats.currentChat) {
        state.chats.currentChat.messages = messages;
        state.chats.currentChat.context = context;
      }
    }
  },
  actions: {
    nextColumn({ commit, state }) {
      return new Promise((resolve) => {
        // console.log('Next column action triggered, current index:', state.activeColumnIndex);
        commit('incrementActiveColumn');
        resolve();
      });
    },
    setActiveColumn({ commit }, index) {
      return new Promise((resolve) => {
        // console.log('Set active column action triggered:', index);
        commit('setActiveColumn', index);
        resolve();
      });
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    clearUserData({ commit }) {
      return new Promise((resolve) => {
        // Perform any async logout operations here (e.g., API calls)
        commit('CLEAR_USER')
        resolve()
      })
    },
    addPoints({ commit }, points) {
      commit('ADD_POINTS', points)
    },
    resetScore({ commit }) {
      commit('RESET_SCORE')
    },
    addAnswer({ commit, state }, { answer, choice, index }) {
      commit('ADD_ANSWER', answer);
      commit('SET_USER_CHOICE', { index, choice });
    },
    resetAnswers({ commit }) {
      commit('RESET_ANSWERS')
    },
    setAssessmentId({ commit }, id) {
      commit('SET_ASSESSMENT_ID', id);
    },
    setShowHeader({ commit }, value) {
      commit('SET_SHOW_HEADER', value)
    },
    updateCurrentRoute({ commit, state }, route) {
      commit('SET_CURRENT_ROUTE', route)
      const hiddenHeaderRoutes = ['business-dashboard', 'admin-dashboard', 'dashboard'];
      commit('SET_SHOW_HEADER', !hiddenHeaderRoutes.includes(route.name))
    },
    async logout({ commit, dispatch }) {
      // Clear auth-related data from localStorage, but keep rememberedEmail
      localStorage.removeItem('authToken');
      localStorage.removeItem('userType');
      localStorage.removeItem('assessmentData');
      
      // Set login state to false
      commit('SET_LOGIN_STATE', false);
      
      // Clear the store
      commit('CLEAR_STORE');
      commit('CLEAR_USER');
    },
    closeWhitePaperBanner({ commit }) {
      commit('SET_WHITEPAPER_BANNER_CLOSED', true)
    },
    updateCurrentSection({ commit }, sectionName) {
      commit('SET_CURRENT_SECTION', sectionName)
    },
    setLoginState({ commit }, value) {
      commit('SET_LOGIN_STATE', value);
    },
    async loadChatHistory({ commit }, chatId) {
      try {
        const response = await loadChatHistory(chatId);
        commit('SET_CURRENT_CHAT', response);
        return response;
      } catch (error) {
        console.error('Error loading chat history:', error);
        throw error;
      }
    },
    async updateChatHistory({ commit }, { messages, context }) {
      try {
        commit('UPDATE_CHAT_HISTORY', { messages, context });
      } catch (error) {
        console.error('Error updating chat history:', error);
        throw error;
      }
    },
    async deleteConversation({ commit }, conversationId) {
      try {
        await deleteConversation(conversationId);
        // You might want to update the chat history here
        commit('SET_CURRENT_CHAT', null);
      } catch (error) {
        console.error('Error deleting conversation:', error);
        throw error;
      }
    }
  },
  modules: {
    swimlane
  }
})
