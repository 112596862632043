<template>
  <div class="proposal-content">
    <h1>Proposal</h1>
    <div class="content-container">
      <!-- Show proposal if it exists -->
      <div v-if="assessmentData.proposal_url" class="proposal-section">
        <div class="proposal-preview">
          <div class="proposal-header">
            <h2>Your Custom AI Implementation Proposal</h2>
            <p>We've prepared a detailed proposal based on our discovery call and assessment of your business needs.</p>
          </div>
          <button @click="viewProposal" class="view-button">
            View Proposal
          </button>
        </div>
      </div>

      <!-- Show call to action if no proposal exists -->
      <div v-else class="proposal-cta">
        <div class="cta-content">
          <img 
            src="https://res.cloudinary.com/dhkjot9fq/image/upload/v1731685748/image2_k2evij.png" 
            alt="Schedule Discovery Call"
            class="cta-image"
          >
          <h2>Schedule Your Discovery Call</h2>
          <p>
            Before we can create your custom AI discovery proposal, we need to learn more about your business.
            Schedule a call with our team to discuss your needs and goals.
          </p>
          <button @click="navigateToSchedule" class="schedule-button">
            Schedule Discovery Call
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  methods: {
    viewProposal() {
      if (this.assessmentData.proposal_url) {
        window.open(this.assessmentData.proposal_url, '_blank');
      }
    },
    navigateToSchedule() {
      this.$emit('navigate', 'DiscoveryCallContent');
    }
  }
}
</script>

<style scoped>
.proposal-content {
  padding: 2rem;
}

.content-container {
  max-width: 800px;
  margin: 2rem auto;
}

.proposal-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.proposal-preview {
  text-align: center;
}

.proposal-header {
  margin-bottom: 2rem;
}

.proposal-header h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
}

.proposal-header p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

.view-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

.proposal-cta {
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cta-content {
  max-width: 600px;
  margin: 0 auto;
}

.cta-image {
  width: 200px;
  height: auto;
  margin-bottom: 2rem;
}

.cta-content h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.cta-content p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.schedule-button {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.schedule-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: translateY(-2px);
}

h1 {
  color: var(--accent-victory-green);
}
</style>
