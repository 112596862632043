# ... existing code ...
<template>
  <div class="vip-container">
    <div class="vip-content">
      <div class="left-column">
        <div class="intro-section">
          <h1>Thank You for Your Feedback!</h1>
          <div class="intro-text">
            <p>We've heard your feedback loud and clear - you want to see real results and success stories. That's why we're offering an exclusive opportunity to be among our first featured case studies.</p>
            <p>For a limited time, we're giving away:</p>
          </div>

          <div class="packages-list">
            <div class="package-info">
              <div class="number-circle">20</div>
              <div class="package-text">Department Discovery Packages (Valued at $5,000)</div>
            </div>

            <p class="offer-terms">These packages are available on a first-come, first-served basis in exchange for a written testimonial and permission to feature your success as a case study.</p>
          </div>
        </div>

        <div class="process-steps">
          <h2>What You'll Get:</h2>
          <ol>
            <li><strong>Introductory Discovery Call</strong> - We'll identify all stakeholders in the department</li>
            <li><strong>Individual Stakeholder Interviews</strong> - We'll interview each stakeholder to identify each department's processes from the bottom up</li>
            <li><strong>Process Mapping</strong> - We'll map all processes using detailed Swimlane Charts</li>
            <li><strong>AI Implementation Recommendations</strong> - We'll recommend AI implementations that can create 10x improvements based on our discovery</li>
            <li><strong>Cost Analysis & ROI Estimates</strong> - You'll receive a detailed cost analysis and ROI estimate report for each AI implementation</li>
            <li><strong>Execute Your Way</strong> - Use the report to execute internally or with our help</li>
          </ol>
        </div>
      </div>

      <div class="right-column">
        <div class="vip-form">
          <h2>Claim Your Free Package</h2>
          
          <div class="top-row">
            <div class="package-options">
              <div 
                class="package-option"
                :class="{ 
                  'selected': true,
                  'disabled': availablePackages[0].remaining === 0 
                }"
              >
                <h3>Department Discovery Package</h3>
                <p class="package-value">Value: $5,000</p>
                <p class="remaining" :class="{ 'sold-out': availablePackages[0].remaining === 0 }">
                  {{ availablePackages[0].remaining === 0 ? 'Sold Out' : `${availablePackages[0].remaining}/20 remaining` }}
                </p>
              </div>
            </div>

            <CountdownTimer class="inline-countdown" />
          </div>

          <div class="package-description">
            <p>Get a complete analysis of one department in your organization. We'll map all processes, interview stakeholders, and identify AI opportunities that can create 10x improvements. You'll receive detailed swimlane charts, implementation recommendations, and cost estimates that you can use to execute internally or with our help.</p>
          </div>

          <div class="form-fields">
            <input 
              type="text" 
              placeholder="First Name"
              v-model="formData.firstName" 
              required
            >
            <input 
              type="email" 
              placeholder="Email"
              v-model="formData.email" 
              required
            >
            <input 
              type="text" 
              placeholder="Company Name"
              v-model="formData.company" 
              required
            >

            <div class="radio-group">
              <label>
                <input 
                  type="radio" 
                  v-model="formData.caseStudyPreference" 
                  value="public"
                >
                <span class="radio-label">Include my logo and link to my website</span>
              </label>
              <label>
                <input 
                  type="radio" 
                  v-model="formData.caseStudyPreference" 
                  value="anonymous"
                >
                <span class="radio-label">Keep my company anonymous</span>
              </label>
            </div>

            <button 
              type="submit" 
              class="submit-btn" 
              :disabled="isSubmitting"
              @click="submitForm"
            >
              {{ isSubmitting ? 'Processing...' : 'Claim Your Free Package' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="showValidationModal || successMessage || errorMessage" @click.self="closeModal">
      <div class="modal-content">
        <button class="modal-close-x" @click="closeModal">&times;</button>
        <template v-if="successMessage">
          <h3>Success!</h3>
          <p class="success-message">{{ successMessage }}</p>
        </template>
        <template v-else-if="errorMessage">
          <h3>Notice</h3>
          <p class="error-message">{{ errorMessage }}</p>
        </template>
        <template v-else>
          <h3>To Continue:</h3>
          <ul class="validation-errors">
            <li v-if="!formData.firstName">First Name is required</li>
            <li v-if="formData.email === ''">Email is required</li>
            <li v-if="formData.email && !validateEmail(formData.email)">Please enter a valid email address</li>
            <li v-if="!formData.company">Company Name is required</li>
            <li v-if="!formData.caseStudyPreference">Please select a case study preference</li>
          </ul>
        </template>
        <button @click="closeModal" class="ok-btn">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import CountdownTimer from '@/components/CountdownTimer.vue'
import { submitVIPGiveaway, getVIPPackageCounts } from '@/services/api'

export default {
  name: 'VIPView',
  components: {
    CountdownTimer
  },
  data() {
    return {
      formData: {
        firstName: '',
        email: '',
        company: '',
        caseStudyPreference: '',
        selectedPackage: 'department'
      },
      isSubmitting: false,
      errorMessage: '',
      successMessage: '',
      showValidationModal: false,
      availablePackages: [
        {
          id: 'department',
          name: 'Department Discovery Package',
          value: 5000,
          remaining: 0,
          description: "Get a complete analysis of one department in your organization. We'll map all processes, interview stakeholders, and identify AI opportunities that can create 10x improvements. You'll receive detailed swimlane charts, implementation recommendations, and cost estimates that you can use to execute internally or with our help."
        }
      ]
    }
  },
  async created() {
    try {
      const counts = await getVIPPackageCounts();
      this.availablePackages = this.availablePackages.map(pkg => ({
        ...pkg,
        remaining: counts['department'] || 0
      }));
    } catch (error) {
      console.error('Failed to fetch package counts:', error);
      this.availablePackages[0].remaining = 20;
    }
  },
  computed: {
    isFormValid() {
      return (
        this.formData.firstName &&
        this.formData.email &&
        this.formData.company &&
        this.formData.caseStudyPreference &&
        this.formData.selectedPackage &&
        !this.isSubmitting
      )
    },
    selectedPackageDetails() {
      return this.availablePackages.find(pkg => pkg.id === this.formData.selectedPackage)
    }
  },
  methods: {
    selectPackage(pkg) {
      if (pkg.remaining > 0) {
        this.formData.selectedPackage = pkg.id;
      }
    },
    closeModal() {
      const hadSuccessMessage = this.successMessage;
      this.showValidationModal = false;
      this.successMessage = '';
      this.errorMessage = '';
      if (hadSuccessMessage) {
        window.location.reload();
      }
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async submitForm() {
      if (!this.formData.firstName || !this.formData.email || !this.formData.company || !this.formData.caseStudyPreference) {
        this.showValidationModal = true;
        return;
      }

      if (!this.validateEmail(this.formData.email)) {
        this.errorMessage = "Please enter a valid email address";
        this.showValidationModal = true;
        return;
      }
      
      this.isSubmitting = true;
      this.errorMessage = '';
      this.successMessage = '';
      
      try {
        const response = await submitVIPGiveaway(
          this.formData.email,
          this.formData.firstName,
          this.formData.company,
          this.formData.caseStudyPreference,
          this.formData.selectedPackage
        )
        
        this.successMessage = response.message;
        // Reset form after successful submission
        this.formData = {
          firstName: '',
          email: '',
          company: '',
          caseStudyPreference: '',
          selectedPackage: 'department'
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.showValidationModal = true;
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style scoped>
.vip-container {
  min-height: calc(100vh - 160px);
  padding: 15px;
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  color: var(--main-text-color);
  overflow-y: auto;
  margin-top: 80px;
}

.vip-content {
  min-height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: start;
  padding-bottom: 2rem;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}

.intro-section {
  margin-bottom: 0;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.8rem;
  color: var(--accent-victory-green);
}

.intro-text {
  margin-bottom: 0;
}

.intro-text p {
  font-size: 0.9rem;
  margin-bottom: 0.6rem;
}

.intro-text p:last-child {
  margin-bottom: 0;
}

.package-row {
  margin: 0.6rem 0;
}

.process-steps {
  background-color: white;
  border-radius: 10px;
  padding: 1.2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.process-steps h2 {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  color: var(--accent-victory-green);
}

.process-steps ol {
  padding-left: 1.2rem;
  margin: 0;
}

.process-steps li {
  font-size: 0.9rem;
  margin: 0.4rem 0;
}

.process-steps li:last-child {
  margin-bottom: 0;
}

.right-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: auto;
  position: sticky;
  top: 1rem;
}

.vip-form {
  background-color: white;
  border-radius: 10px;
  padding: 1.2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.package-options {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
  margin-top: 15px;
}

.package-option {
  width: 80%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.package-option h3 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.4rem;
}

.package-value {
  font-size: 0.85rem;
  margin: 0.2rem 0;
}

.remaining {
  font-size: 0.75rem;
}

.package-description {
  font-size: 0.85rem;
  line-height: 1.4;
  margin: 1.5rem 0 0 0;
  padding: 0 0.5rem;
}

.form-fields {
  display: grid;
  gap: 0.8rem;
  margin-top: 1.5rem;
}

input[type="text"],
input[type="email"] {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.radio-group {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.radio-group label {
  font-size: 0.85rem;
}

.submit-btn {
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.package-row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0.6rem 0;
}

.number-circle {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  font-weight: bold;
  font-size: 1.2rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer-terms {
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 1rem;
  color: #666;
}

.package-option:hover:not(.disabled) {
  border-color: var(--accent-victory-green);
}

.package-option.selected {
  border-color: var(--accent-victory-green);
  background-color: var(--accent-sunny-yellow);
}

.package-option.selected h3,
.package-option.selected .package-value {
  color: var(--accent-victory-green);
}

.radio-group {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.radio-label {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.submit-btn {
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.submit-btn:hover:not(:disabled) {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

@media (max-width: 1024px) {
  .vip-container {
    height: auto;
    overflow: auto;
  }

  .vip-content {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .package-options {
    grid-template-columns: 1fr;
  }

  .left-column {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .vip-container {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
    margin-bottom: 2rem;
  }

  .container {
    flex-direction: column;
    padding: 0.75rem;
    gap: 1rem;
  }

  .vip-content {
    grid-template-columns: 1fr;
    padding: 0.5rem;
    height: auto;
  }

  .left-column, 
  .right-column {
    width: 100%;
    padding: 0;
    max-height: none;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 1.25rem;
  }

  .intro-text {
    font-size: 1.1rem;
    line-height: 1.4;
    margin-bottom: 1.25rem;
  }

  .packages-list {
    margin: 1rem 0;
  }

  .package-info {
    background: rgba(255, 255, 255, 0.9);
    padding: 0.75rem 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0.875rem 0;
  }

  .package-text {
    font-size: 1.1rem;
    line-height: 1.3;
  }

  .number-circle {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.4rem;
  }

  .offer-terms {
    font-style: italic;
    font-size: 1rem;
    color: #666;
    margin: 1.25rem 0;
    line-height: 1.4;
    padding: 0 0.5rem;
  }

  .vip-form {
    padding: 1.25rem;
    margin-bottom: 3rem;
  }

  .package-options {
    gap: 0.75rem;
    margin-bottom: 1rem;
  }

  .package-option {
    padding: 0.75rem;
  }

  .package-option h3 {
    font-size: 0.95rem;
    margin-bottom: 0.3rem;
  }

  .package-value,
  .remaining {
    font-size: 0.8rem;
  }

  .package-description {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: 0.75rem 0.25rem 1rem;
    color: #666;
    padding: 0;
  }

  .form-fields {
    gap: 0.75rem;
    margin-top: 1.25rem;
    padding-bottom: 1rem;
  }

  input[type="text"],
  input[type="email"] {
    padding: 0.75rem;
    font-size: 0.95rem;
  }

  .radio-group {
    margin: 0.75rem 0 1.25rem;
  }

  .radio-label {
    font-size: 0.85rem;
  }

  .submit-btn {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    font-size: 0.95rem;
  }

  .package-description p {
    font-size: 0.75rem;
    line-height: 1.3;
    margin-bottom: 0.5rem;
    color: #666;
  }

  .modal-content {
    width: 85%;
    padding: 1.5rem;
    margin: 0 1rem;
  }
}

@media (max-width: 480px) {
  .vip-content {
    padding: 0.5rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  .intro-text {
    font-size: 1rem;
  }

  .package-text {
    font-size: 1rem;
  }

  .number-circle {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3rem;
  }

  .package-info {
    padding: 0.7rem;
  }

  .vip-form {
    padding: 1rem;
    margin-bottom: 4rem;
  }

  .package-option {
    padding: 0.6rem;
  }

  .package-description {
    font-size: 0.7rem;
    line-height: 1.25;
    margin: 0.5rem 0.25rem 0.75rem;
    color: #555;
  }

  .package-description p {
    font-size: 0.7rem;
    line-height: 1.25;
    margin-bottom: 0.4rem;
    color: #555;
  }

  .package-option h3 {
    font-size: 0.9rem;
  }

  .package-value,
  .remaining {
    font-size: 0.75rem;
  }

  input[type="text"],
  input[type="email"] {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  .radio-label {
    font-size: 0.8rem;
  }

  .submit-btn {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .modal-content {
    width: 80%;
    padding: 1.25rem;
    margin: 0 1rem;
  }
}

.packages-list {
  margin: 1rem 0;
}

.package-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0.75rem 0;
}

.number-circle {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  font-weight: bold;
  font-size: 1.2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.package-text {
  font-size: 0.95rem;
  line-height: 1.3;
  flex-grow: 1;
}

.error-message {
  color: #dc3545;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.1rem;
}

.success-message {
  color: #28a745;
  text-align: center;
  margin: 1rem 0;
  font-size: 1.1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.modal-close-x {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  line-height: 1;
}

.modal-close-x:hover {
  color: #333;
}

.ok-btn {
  width: 100%;
  padding: 0.75rem;
  background: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1rem;
}

.ok-btn:hover {
  opacity: 0.9;
}

.validation-errors {
  color: #dc3545;
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.remaining.sold-out {
  color: #dc3545;
  font-weight: bold;
}

.package-option.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #f8f9fa;
}

.package-option.disabled:hover {
  border-color: #ddd;
}

.package-option.disabled h3,
.package-option.disabled .package-value {
  color: #6c757d;
}

.top-row {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.2rem;
  align-items: start;
  margin-bottom: 1.5rem;
}

.inline-countdown {
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .top-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .inline-countdown {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1200px) {
  .package-option {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .package-option {
    width: 100%;
  }
  
  .top-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}

/* Add these desktop-specific styles before the media queries */
@media (min-width: 769px) {
  h1 {
    font-size: calc(2rem - 2px);
  }

  .intro-text p {
    font-size: calc(0.9rem - 2px);
  }

  .process-steps h2 {
    font-size: calc(1.2rem - 2px);
  }

  .process-steps li {
    font-size: calc(0.9rem - 2px);
  }

  .package-option h3 {
    font-size: calc(1rem - 2px);
  }

  .package-value {
    font-size: calc(0.85rem - 2px);
  }

  .remaining {
    font-size: calc(0.75rem - 2px);
  }

  .package-description {
    font-size: calc(0.85rem - 2px);
  }

  input[type="text"],
  input[type="email"] {
    font-size: calc(0.9rem - 2px);
  }

  .radio-group label {
    font-size: calc(0.85rem - 2px);
  }

  .submit-btn {
    font-size: calc(1rem - 2px);
  }

  .offer-terms {
    font-size: calc(0.9rem - 2px);
  }

  .package-text {
    font-size: calc(0.95rem - 2px);
  }
}
</style> 