<template>
  <div class="admin-sidebar">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Victory AI Logo" class="logo">
    </div>
    <nav>
      <ul>
        <li>
          <a @click="navigate('AdminDashboardContent')" 
             :class="['nav-link', { 'active': currentComponent === 'AdminDashboardContent' }]">
            Leads
          </a>
        </li>
        <li>
          <a @click="navigate('ChatInterface')" 
             :class="['nav-link', { 'active': currentComponent === 'ChatInterface' }]">
            Chat
          </a>
        </li>
      </ul>
    </nav>
    <div class="logout-container">
      <button @click="logout" class="logout-button">Logout</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AdminSidebar',
  props: {
    currentComponent: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(['clearUserData']),
    navigate(component) {
      this.$emit('navigate', component);
    },
    async logout() {
      try {
        await this.clearUserData();
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userType');
        localStorage.removeItem('authToken');
        this.$router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  }
}
</script>

<style scoped>
.admin-sidebar {
  width: 200px;
  background-color: #f4f4f4;
  height: 95vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 180px;
  height: auto;
}

nav {
  flex-grow: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
}

.nav-link {
  text-decoration: none;
  color: var(--accent-victory-green);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  display: block;
  border-radius: 5px;
}

.nav-link:hover, .nav-link.active {
  background-color: var(--secondary-background-sky-blue);
  color: white;
  transform: translateX(5px);
}

.logout-container {
  margin-top: auto;
}

.logout-button {
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}

.logout-button:hover {
  background-color: #c82333;
}
</style> 