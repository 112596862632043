<template>
  <div class="assessment-content">
    <div v-if="assessmentData && assessmentData.ai_report" class="dashboard-container">
      <!-- Status Card -->
      <div class="status-card">
        <div class="status-content">
          <div class="status-text">
            <h2 class="status-header" :class="riskLevelClass">{{ riskLevelText }}</h2>
            <p class="status-description">
              {{ riskDescription }}
            </p>
            <button @click="showPdfViewer = true" class="btn-primary">
              View Summary PDF
            </button>
          </div>
          <div class="status-image">
            <img 
              :src="riskImage" 
              alt="Status Illustration"
            >
          </div>
        </div>
      </div>

      <!-- PDF Viewer Modal -->
      <div v-if="showPdfViewer" class="pdf-modal">
        <div class="pdf-modal-content">
          <button @click="showPdfViewer = false" class="close-button">×</button>
          <iframe 
            :src="assessmentData.report_url + '#view=FitH'" 
            class="pdf-viewer"
            type="application/pdf"
          ></iframe>
        </div>
      </div>

      <!-- Header Row with Filter -->
      <div class="report-header-row">
        <h2 class="detailed-report-header">Detailed Report</h2>
        <div class="filter-container">
          <select 
            v-model="selectedRiskFilter" 
            class="risk-filter"
            :data-value="selectedRiskFilter"
          >
            <option value="all">All Risks</option>
            <option value="high">High Risk</option>
            <option value="medium">Medium Risk</option>
            <option value="low">Low Risk</option>
          </select>
        </div>
      </div>

      <!-- Scrollable Detailed Report Section -->
      <div class="detailed-report-container">
        <div class="detailed-report">
          <div class="feedback-sections">
            <div v-if="filteredQuestions.length > 0">
              <div 
                v-for="(question, index) in filteredQuestions" 
                :key="index" 
                class="feedback-section"
              >
                <div class="question-header">
                  <div class="header-content">
                    <h3>{{ question.headline }}</h3>
                    <div class="risk-indicator" :class="getRiskClass(assessmentData.user_choices[question.originalIndex])">
                      {{ getRiskText(assessmentData.user_choices[question.originalIndex]) }}
                    </div>
                  </div>
                  <p class="question-text">{{ question.question }}</p>
                </div>
                <div class="feedback-content">
                  {{ assessmentData.feedback_map[question.originalIndex] }}
                </div>
              </div>
            </div>
            <div v-else class="no-results">
              {{ noResultsMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show AssessmentEmbed if no assessment exists -->
    <div v-else class="assessment-embed-wrapper">
      <AssessmentEmbed 
        :initialFirstName="assessmentData.name"
        :initialEmail="assessmentData.email"
        @navigate="$emit('navigate', $event)"
      />
    </div>
  </div>
</template>

<script>
import AssessmentEmbed from '@/components/AssessmentEmbed.vue'

export default {
  name: 'ExistingBusinessAssessmentResults',
  components: {
    AssessmentEmbed
  },
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPdfViewer: false,
      selectedRiskFilter: 'all',
      assessmentQuestions: [
        { 
          headline: 'Executive Management', 
          question: 'How is your company using AI for decision-making?'
        },
        { 
          headline: 'HR', 
          question: 'How does your HR department use AI for talent acquisition?'
        },
        { 
          headline: 'Finance', 
          question: 'How does AI assist with financial reporting?'
        },
        { 
          headline: 'Operations', 
          question: 'How does your company use AI to optimize costs and financial efficiency?'
        },
        { 
          headline: 'Marketing', 
          question: 'How is AI used to personalize marketing campaigns?'
        },
        { 
          headline: 'Sales', 
          question: 'How does AI improve sales conversion rates through interaction analysis?'
        },
        { 
          headline: 'IT', 
          question: 'How does your company leverage AI for IT service desk and support?'
        },
        { 
          headline: 'R&D', 
          question: 'How does AI assist in your product development process?'
        },
        { 
          headline: 'Customer Service', 
          question: 'How is AI improving customer support?'
        },
        { 
          headline: 'Legal', 
          question: 'How does AI support contract management?'
        },
        { 
          headline: 'Procurement', 
          question: 'How does AI assist in supplier management and procurement processes?'
        },
        { 
          headline: 'Facilities/Administration', 
          question: 'How does your company use AI to optimize workplace experience and productivity?'
        },
        { 
          headline: 'Corporate Strategy', 
          question: 'How does AI support long-term strategic planning?'
        },
        { 
          headline: 'Compliance and Risk Management', 
          question: 'How does AI assist in monitoring compliance and risks?'
        },
        { 
          headline: 'Product Management', 
          question: 'How does AI influence product roadmapping?'
        }
      ]
    }
  },
  computed: {
    filteredQuestions() {
      return this.assessmentQuestions.map((question, index) => ({
        ...question,
        originalIndex: index
      })).filter(question => {
        const choice = this.assessmentData.user_choices[question.originalIndex];
        switch (this.selectedRiskFilter) {
          case 'high':
            return choice === 3;
          case 'medium':
            return choice === 2;
          case 'low':
            return choice === 0 || choice === 1;
          default:
            return true;
        }
      });
    },
    noResultsMessage() {
      switch (this.selectedRiskFilter) {
        case 'high':
          return 'No High Risk Areas Found';
        case 'medium':
          return 'No Medium Risk Areas Found';
        case 'low':
          return 'No Low Risk Areas Found';
        default:
          return 'No Results Found';
      }
    },
    totalScore() {
      if (!this.assessmentData.user_choices) return 0;
      return Object.values(this.assessmentData.user_choices).reduce((sum, choice) => sum + choice, 0);
    },

    riskLevel() {
      if (this.totalScore > 25) return 'high';
      if (this.totalScore >= 10) return 'medium';
      return 'low';
    },

    riskLevelText() {
      switch (this.riskLevel) {
        case 'high':
          return 'High Risk';
        case 'medium':
          return 'Medium Risk';
        case 'low':
          return 'Low Risk';
        default:
          return 'Unknown Risk';
      }
    },

    riskDescription() {
      switch (this.riskLevel) {
        case 'high':
          return 'Your organization is at high risk for AI disruption over the next 12 months. Immediate action is recommended to maintain competitiveness.';
        case 'medium':
          return 'Your organization shows moderate vulnerability to AI disruption. Strategic improvements could significantly enhance your competitive position.';
        case 'low':
          return 'Your organization demonstrates strong AI readiness. Continue to innovate and maintain your competitive advantage.';
        default:
          return 'Assessment status unavailable.';
      }
    },

    riskImage() {
      switch (this.riskLevel) {
        case 'high':
          return 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731941804/High_Risk-removebg-preview_w0moyb.png';
        case 'medium':
          return 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731941804/Medium_Risk-removebg-preview_v01qvb.png';
        case 'low':
          return 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731941804/low_risk-removebg-preview_mqfiwn.png';
        default:
          return 'https://res.cloudinary.com/dhkjot9fq/image/upload/v1731941804/Medium_Risk-removebg-preview_v01qvb.png';
      }
    },

    riskLevelClass() {
      return `${this.riskLevel}-risk-text`;
    }
  },
  methods: {
    getRiskText(choice) {
      if (choice === 3) return 'High Risk';
      if (choice === 2) return 'Medium Risk';
      return 'Low Risk';
    },
    getRiskClass(choice) {
      if (choice === 3) return 'high-risk';
      if (choice === 2) return 'medium-risk';
      return 'low-risk';
    }
  }
}
</script>

<style scoped>
.assessment-content {
  height: calc(100vh - 4rem);
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}

.status-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.status-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.status-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.status-header {
  font-size: 1.8rem;
  margin: 0;
}

.status-description {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #333;
  margin: 0;
}

.btn-primary {
  background-color: var(--accent-victory-green);
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  width: fit-content;
}

.btn-primary:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.status-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-image img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
}

.pdf-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pdf-modal-content {
  position: relative;
  width: 90%;
  height: 90%;
  background: white;
  border-radius: 12px;
  padding: 1rem;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none;
}

.detailed-report-header {
  margin: 0;
  color: var(--accent-victory-green);
}

.detailed-report-container {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detailed-report {
  padding: 1.5rem;
}

/* Remove the old h2 margin since we moved it outside */
.detailed-report h2 {
  display: none;
}

.feedback-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feedback-section {
  border-bottom: 1px solid #eee;
  padding-bottom: 1.5rem;
}

.question-header {
  margin-bottom: 1rem;
}

.question-header h3 {
  margin: 0;
  color: var(--accent-victory-green);
}

.question-text {
  margin: 0;
  color: #666;
  font-style: italic;
}

.feedback-content {
  line-height: 1.6;
  color: #333;
}

.assessment-embed-wrapper {
  flex: 1;
  background: var(--primary-background-cream);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  padding-top: 15px;
}

.risk-indicator {
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--primary-background-cream);
  white-space: nowrap;
}

.low-risk {
  background-color: var(--secondary-background-sky-blue);
}

.medium-risk {
  background-color: var(--accent-sunny-yellow);
}

.high-risk {
  background-color: #FF4444;
}

.question-header h3 {
  margin: 0;
  color: var(--accent-victory-green);
}

/* Update question header layout */
.question-header {
  margin-bottom: 1rem;
}

.question-text {
  margin: 0;
  color: #666;
  font-style: italic;
}

.report-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background: var(--primary-background-cream);
}

.filter-container {
  position: relative;
}

.risk-filter {
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 1em;
  color: white;
  background-color: var(--accent-victory-green);
}

.risk-filter:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-sunny-yellow);
}

.risk-filter option {
  padding: 0.5rem;
  color: white;
  font-weight: 500;
}

.risk-filter option[value="high"] {
  background-color: #FF4444;
}

.risk-filter option[value="medium"] {
  background-color: var(--accent-sunny-yellow);
}

.risk-filter option[value="low"] {
  background-color: var(--secondary-background-sky-blue);
}

.risk-filter option[value="all"] {
  background-color: var(--accent-victory-green);
}

.risk-filter[data-value="high"] {
  background-color: #FF4444;
}

.risk-filter[data-value="medium"] {
  background-color: var(--accent-sunny-yellow);
}

.risk-filter[data-value="low"] {
  background-color: var(--secondary-background-sky-blue);
}

.risk-filter[data-value="all"] {
  background-color: var(--accent-victory-green);
}

.no-results {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.1rem;
  background: #f9f9f9;
  border-radius: 8px;
  margin: 1rem 0;
}

/* Style the select when different options are selected */
.risk-filter.high-selected {
  color: #FF4444;
  border-color: #FF4444;
}

.risk-filter.medium-selected {
  color: var(--accent-sunny-yellow);
  border-color: var(--accent-sunny-yellow);
}

.risk-filter.low-selected {
  color: var(--secondary-background-sky-blue);
  border-color: var(--secondary-background-sky-blue);
}

/* Risk level text colors */
.high-risk-text {
  color: #FF4444;
}

.medium-risk-text {
  color: var(--accent-sunny-yellow);
}

.low-risk-text {
  color: var(--secondary-background-sky-blue);
}

</style>
