<template>
  <div class="infinite-canvas-wrapper" 
       @wheel.prevent="handleZoom"
       @mousedown.left="startPan"
       @mousemove="pan"
       @mouseup.left="stopPan"
       @mouseleave="stopPan">
    <div class="department-menu">
      <h3>Departments</h3>
      <ul>
        <li v-for="dept in departments" :key="dept.id">
          <button 
            class="department-btn"
            :class="{ active: selectedDepartment === dept.id }"
            @click="selectDepartment(dept.id)"
          >
            {{ dept.name }}
          </button>
        </li>
      </ul>
    </div>
    <div class="zoom-controls">
      <div class="zoom-percentage">{{ Math.round(scale * 100) }}%</div>
      <div class="zoom-buttons">
        <button @click="zoomIn">+</button>
        <button @click="zoomOut">-</button>
        <button @click="resetZoom">Reset</button>
      </div>
    </div>
    <div class="infinite-canvas-container" 
         :style="transformStyle">
      <slot v-if="shouldShowContent"></slot>
    </div>
    <ChatDialog />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ChatDialog from './ChatDialog.vue'

export default {
  name: 'InfiniteCanvas',
  components: {
    ChatDialog
  },
  data() {
    return {
      scale: 0.5,
      minScale: 0.1,
      maxScale: 3,
      panX: 0,
      panY: 0,
      isPanning: false,
      lastX: 0,
      lastY: 0,
      zoomStep: 0.1,
      selectedDepartment: 'sales_dept' // Default to sales department
    }
  },
  computed: {
    ...mapState('swimlane', ['departments']),
    ...mapGetters('swimlane', ['getDepartmentSwimlanes']),
    
    transformStyle() {
      return {
        transform: `translate(${this.panX}px, ${this.panY}px) scale(${this.scale})`,
        transformOrigin: '0 0'
      }
    },

    shouldShowContent() {
      // Get swimlanes for selected department
      const swimlanes = this.getDepartmentSwimlanes(this.selectedDepartment)
      return swimlanes.length > 0
    }
  },
  methods: {
    handleZoom(event) {
      event.preventDefault()
      const delta = -event.deltaY
      
      const wrapper = event.currentTarget
      const rect = wrapper.getBoundingClientRect()
      const mouseX = event.clientX - rect.left
      const mouseY = event.clientY - rect.top

      const newScale = delta > 0 
        ? Math.min(this.scale * 1.1, this.maxScale)
        : Math.max(this.scale * 0.9, this.minScale)

      if (newScale !== this.scale) {
        const mouseXInContent = (mouseX - this.panX) / this.scale
        const mouseYInContent = (mouseY - this.panY) / this.scale

        this.panX = mouseX - mouseXInContent * newScale
        this.panY = mouseY - mouseYInContent * newScale
        this.scale = newScale
        this.$emit('scale-change', newScale)
      }
    },

    zoomIn() {
      const wrapper = this.$el
      const rect = wrapper.getBoundingClientRect()
      const centerX = rect.width / 2
      const centerY = rect.height / 2
      
      const newScale = Math.min(this.scale * 1.1, this.maxScale)
      if (newScale !== this.scale) {
        const centerXInContent = (centerX - this.panX) / this.scale
        const centerYInContent = (centerY - this.panY) / this.scale
        
        this.panX = centerX - centerXInContent * newScale
        this.panY = centerY - centerYInContent * newScale
        this.scale = newScale
      }
    },

    zoomOut() {
      const wrapper = this.$el
      const rect = wrapper.getBoundingClientRect()
      const centerX = rect.width / 2
      const centerY = rect.height / 2
      
      const newScale = Math.max(this.scale * 0.9, this.minScale)
      if (newScale !== this.scale) {
        const centerXInContent = (centerX - this.panX) / this.scale
        const centerYInContent = (centerY - this.panY) / this.scale
        
        this.panX = centerX - centerXInContent * newScale
        this.panY = centerY - centerYInContent * newScale
        this.scale = newScale
      }
    },

    resetZoom() {
      this.scale = 0.5
      this.$nextTick(() => {
        this.centerContent()
      })
    },

    startPan(event) {
      // Don't start panning if clicking on swimlane, controls, or grid area
      if (event.target.closest('.swimlane-container') || 
          event.target.closest('.department-menu') || 
          event.target.closest('.zoom-controls') ||
          event.target.closest('.grid-container')) {
        return
      }

      this.isPanning = true
      this.lastX = event.clientX
      this.lastY = event.clientY
      event.target.style.cursor = 'grabbing'
    },

    pan(event) {
      if (!this.isPanning) return

      const deltaX = event.clientX - this.lastX
      const deltaY = event.clientY - this.lastY

      this.panX += deltaX
      this.panY += deltaY

      this.lastX = event.clientX
      this.lastY = event.clientY
    },

    stopPan(event) {
      if (this.isPanning) {
        this.isPanning = false
        if (event && event.target) {
          event.target.style.cursor = 'grab'
        }
      }
    },

    centerContent() {
      const wrapper = this.$el
      const container = this.$el.querySelector('.infinite-canvas-container')
      
      if (wrapper && container) {
        const wrapperRect = wrapper.getBoundingClientRect()
        
        this.panX = (wrapperRect.width / 2) - (container.scrollWidth * this.scale / 2)
        
        this.panY = (wrapperRect.height * 0.2)
      }
    },

    selectDepartment(departmentId) {
      this.selectedDepartment = departmentId
      this.$nextTick(() => {
        this.centerContent()
      })
    }
  },

  beforeDestroy() {
    this.isPanning = false
    document.body.style.cursor = 'default'
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$el && typeof this.$el.querySelector === 'function') {
        this.centerContent()
      }
    })
  }
}
</script>

<style scoped>
.infinite-canvas-wrapper {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;
  cursor: grab;
  background: var(--primary-background-cream);
  user-select: none;
  padding: 2rem;
}

.infinite-canvas-wrapper:active {
  cursor: grabbing;
}

.infinite-canvas-container {
  height: 100%;
  transform-origin: 0 0;
  will-change: transform;
  pointer-events: none;
  min-width: fit-content;
  display: inline-block;
  background: var(--primary-background-cream);
  padding: 2rem;
}

.infinite-canvas-container > *,
.infinite-canvas-container .grid-container,
.infinite-canvas-container .flow-node {
  pointer-events: auto;
}

.zoom-controls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.zoom-percentage {
  text-align: center;
  color: var(--main-text-color);
  font-size: 14px;
  margin-bottom: 4px;
}

.zoom-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.zoom-buttons button {
  width: 36px;
  height: 36px;
  margin: 0;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.zoom-buttons button:hover {
  background: var(--secondary-background-sky-blue);
}

.zoom-buttons button:last-child {
  width: auto;
  padding: 0 12px;
  font-size: 14px;
  height: 36px;
}

.department-menu {
  position: fixed;
  left: 20px;
  top: 20%;
  z-index: 1000;
  background: var(--primary-background-cream);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.department-menu h3 {
  margin: 0 0 12px 0;
  color: var(--main-text-color);
  font-size: 16px;
}

.department-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.department-menu li {
  margin: 8px 0;
}

.department-btn {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background: var(--accent-victory-green);
  color: var(--primary-background-cream);
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.department-btn.active {
  background-color: var(--secondary-background-sky-blue);
}

.department-btn:hover:not(.active) {
  background-color: var(--secondary-background-sky-blue);
  opacity: 0.8;
}

.infinite-canvas-container .grid-container {
  cursor: default;
}

.infinite-canvas-container .flow-node {
  cursor: grab;
}

.infinite-canvas-container .flow-node:active {
  cursor: grabbing;
}

.infinite-canvas-wrapper .grid-container {
  cursor: default !important;
}

.infinite-canvas-wrapper .flow-node {
  cursor: grab !important;
}

.infinite-canvas-wrapper .flow-node:active {
  cursor: grabbing !important;
}
</style> 