<template>
  <div class="schedule-info">
    <h2>Book A Call</h2>
    <p>
      Take the first step towards transforming your business with AI. Schedule a 30-minute strategy session with our experts to:
    </p>
    <ul>
      <li>(5-10 minutes) Give you a presentation explaining what Victory AI is and how we help businesses implement a guaranteed winning AI strategy.</li>
      <li>(2-5 minutes) Quickly go over your assessment results (if you've taken the assessment)</li>
      <li>(15-20 minutes) We'll learn about your company, goals and culture to see if we're a good fit to work together.</li>
    </ul>
    <hr class="divider">
    <p class="post-call-info"><i>After the call is concluded you will receive a summary of the call and a Stripe link for you to use if you decide to move forward.</i></p>
  </div>
</template>

<script>
export default {
  name: 'ScheduleInfo'
}
</script>

<style scoped>
.schedule-info {
  max-width: 400px;
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

li::before {
  content: '✓';
  color: var(--accent-victory-green);
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
}

.divider {
  border: 0;
  border-top: 1px solid var(--accent-victory-green);
  margin: 20px 0;
}

.post-call-info {
  font-size: 0.9em;
  color: #666;
}

@media (max-width: 768px) {
  .schedule-info {
    max-width: 100%;
    padding: 10px;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 15px;
  }

  li {
    font-size: 0.9rem;
    margin-bottom: 12px;
    padding-left: 25px;
    line-height: 1.4;
  }

  .post-call-info {
    font-size: 0.8rem;
  }
}
</style>
