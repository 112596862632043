<template>
  <div class="node-container" 
       :style="nodeStyle"
       @mousedown.prevent.stop="startDrag"
       @mouseenter="isHovered = true"
       @mouseleave="isHovered = false">
    <div class="node">
      <div class="node-content">
        <div class="node-title">{{ title }}</div>
        <div class="node-description" @click.stop="startEditing" v-if="!isEditing">
          {{ description || 'Task description goes here...' }}
        </div>
        <input
          v-else
          ref="descriptionInput"
          v-model="editedDescription"
          class="description-input"
          @blur="saveDescription"
          @keyup.enter="saveDescription"
          @keyup.esc="cancelEdit"
        />
        <div class="node-icon">
          <i class="fas fa-cog"></i>
        </div>
      </div>

      <!-- Connection points -->
      <div class="connection-point entry-point"></div>
      <div class="connection-point exit-point"></div>

      <!-- Combined Controls Menu -->
      <div class="node-controls" v-show="isHovered">
        <button class="control-btn delete-btn" @click.stop="$emit('remove', id)">
          <i class="fas fa-minus"></i>
        </button>
        <button class="control-btn add-btn" @click.stop="openNodeTypeSelector">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlowNode',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    title: {
      type: String,
      default: 'Node'
    },
    position: {
      type: Object,
      required: true,
      default: () => ({ x: 0, y: 0 })
    },
    type: {
      type: String,
      default: 'activity'
    },
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      dragStartTime: 0,
      dragStartPos: { x: 0, y: 0 },
      showEastControls: false,
      isEditing: false,
      editedDescription: '',
      isHovered: false
    }
  },
  computed: {
    nodeStyle() {
      const xOffset = 100 - (200 / 2)  // Keep horizontal position the same
      const yOffset = 35 - (100 / 2)   // Changed from 30 to 35 to move down 5px

      return {
        position: 'absolute',
        transform: `translate(${this.position.x + xOffset}px, ${this.position.y + yOffset}px)`
      }
    }
  },
  methods: {
    startDrag(event) {
      event.preventDefault()
      event.stopPropagation()
      
      if (event.button !== 0) return // Only left mouse button
      
      this.dragStartTime = Date.now()
      this.dragStartPos = {
        x: event.clientX,
        y: event.clientY
      }
      
      this.isDragging = true
      const scale = this.$parent.scale || 1
      
      const gridContainer = this.$el.closest('.grid-container')
      const gridRect = gridContainer.getBoundingClientRect()
      
      this.dragOffset = {
        x: event.clientX - gridRect.left - (this.position.x * scale),
        y: event.clientY - gridRect.top - (this.position.y * scale)
      }
      
      window.addEventListener('mousemove', this.handleDrag)
      window.addEventListener('mouseup', this.endDrag)
    },
    
    handleDrag(event) {
      if (!this.isDragging) return
      
      event.preventDefault()
      event.stopPropagation()
      
      const distance = Math.sqrt(
        Math.pow(event.clientX - this.dragStartPos.x, 2) +
        Math.pow(event.clientY - this.dragStartPos.y, 2)
      )
      
      if (distance < 5) return
      
      const scale = this.$parent.scale || 1
      const gridContainer = this.$el.closest('.grid-container')
      const gridRect = gridContainer.getBoundingClientRect()
      
      const newPosition = {
        x: (event.clientX - gridRect.left - this.dragOffset.x) / scale,
        y: (event.clientY - gridRect.top - this.dragOffset.y) / scale
      }
      
      this.$emit('nodeDrag', {
        id: this.id,
        position: newPosition,
        event
      })
    },
    
    endDrag(event) {
      if (!this.isDragging) return
      
      event.preventDefault()
      event.stopPropagation()
      
      const duration = Date.now() - this.dragStartTime
      const distance = Math.sqrt(
        Math.pow(event.clientX - this.dragStartPos.x, 2) +
        Math.pow(event.clientY - this.dragStartPos.y, 2)
      )
      
      if (duration < 200 && distance < 5) {
        this.isDragging = false
        window.removeEventListener('mousemove', this.handleDrag)
        window.removeEventListener('mouseup', this.endDrag)
        return
      }
      
      this.isDragging = false
      window.removeEventListener('mousemove', this.handleDrag)
      window.removeEventListener('mouseup', this.endDrag)
      
      this.$emit('nodeDragEnd', {
        id: this.id,
        event
      })
    },
    
    addConnectedNode() {
      const columnWidth = 264 + 100 // cell width + gap
      const newNodePosition = {
        x: this.position.x + columnWidth,
        y: this.position.y
      }
      
      this.$emit('addNode', {
        sourceId: this.id,
        position: newNodePosition
      })
    },
    
    removeNode() {
      this.$emit('removeNode', this.id)
    },
    
    openNodeTypeSelector() {
      const rect = this.$el.getBoundingClientRect()
      this.$emit('openNodeSelector', {
        sourceId: this.id,
        position: {
          x: rect.right + 20,  // Position menu to the right of the node
          y: rect.top + rect.height / 2
        }
      })
    },
    startEditing() {
      this.isEditing = true
      this.editedDescription = this.description || 'Task description goes here...'
      this.$nextTick(() => {
        this.$refs.descriptionInput.focus()
        this.$refs.descriptionInput.select()
      })
    },
    
    saveDescription() {
      this.isEditing = false
      const newDescription = this.editedDescription.trim()
      if (newDescription !== this.description) {
        this.$emit('update:description', newDescription)
      }
    },
    
    cancelEdit() {
      this.isEditing = false
      this.editedDescription = this.description
    },
    handleClickOutside(event) {
      if (this.isEditing && !this.$el.contains(event.target)) {
        this.saveDescription()
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
    window.removeEventListener('mousemove', this.handleDrag)
    window.removeEventListener('mouseup', this.endDrag)
  }
}
</script>

<style scoped>
.node-container {
  position: absolute;
  background: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  min-width: 200px;
  user-select: none;
  z-index: 3;
  touch-action: none;
  will-change: transform;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;
  cursor: grab;
  --translate-x: 0px;
  --translate-y: 0px;
}

.node-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.node-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
}

.node-title {
  font-weight: 600;
  color: var(--main-text-color);
  text-align: center;
}

.node-description {
  font-size: 12px;
  color: #666;
  text-align: center;
  line-height: 1.4;
  margin: 4px 0;
  cursor: text;
  padding: 2px 4px;
  border-radius: 4px;
}

.node-description:hover {
  background: rgba(0, 0, 0, 0.05);
}

.node-icon {
  color: var(--accent-victory-green);
  font-size: 20px;
  margin-top: 4px;
}

.node-controls {
  position: absolute;
  right: -40px;  /* Position to the right of the node */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;  /* Stack buttons vertically */
  gap: 4px;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px;
  border-radius: 4px;
  z-index: 1000;
}

.control-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  transition: background-color 0.2s;
}

.delete-btn {
  background-color: #dc3545;
}

.delete-btn:hover {
  background-color: #bd2130;
}

.add-btn {
  background-color: var(--accent-victory-green);
}

.add-btn:hover {
  background-color: #0D4E36;
}

.node-container:active,
.node-content:active {
  cursor: grabbing;
}

.decision-node {
  width: 160px;
  height: 160px;
  min-width: unset;
  transform: translate(var(--translate-x), var(--translate-y)) rotate(45deg);
  transform-origin: center;
  background: white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.decision-node .node-content {
  transform: rotate(-45deg);
  padding: 16px;
  width: 220px;
  height: 160px;
  position: absolute;
  left: -30px;
  top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.decision-node .node-header {
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.decision-node .node-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: var(--main-text-color);
}

.decision-node .node-status {
  font-size: 12px;
  padding: 2px 8px;
  background: var(--accent-victory-green);
  color: white;
  border-radius: 12px;
}

.decision-node .node-body {
  display: none;
}

.decision-node .node-handle {
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
}

.decision-node .handle-east {
  right: -25px;
  top: 50%;
}

.decision-node .handle-west {
  left: -25px;
  top: 50%;
}

/* Update Yes/No labels */
.decision-node::before,
.decision-node::after {
  content: 'Yes';
  position: absolute;
  top: -20px;
  right: -20px;
  left: unset;
  bottom: unset;
  transform: rotate(-45deg);
  font-size: 12px;
  font-weight: 600;
  color: white;
  background: rgba(9, 57, 39, 0.8);
  padding: 2px 8px;
  border-radius: 4px;
  z-index: 2;
}

.decision-node::after {
  content: 'No';
  top: unset;
  right: -20px;
  bottom: -20px;
  transform: rotate(-45deg);
}

.description-input {
  font-size: 12px;
  color: #666;
  text-align: center;
  line-height: 1.4;
  margin: 4px 0;
  padding: 2px 4px;
  border: 1px solid var(--accent-victory-green);
  border-radius: 4px;
  width: 90%;
  outline: none;
  background: white;
}

.description-input:focus {
  box-shadow: 0 0 0 2px rgba(9, 57, 39, 0.1);
}

.connection-point {
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--accent-victory-green);
  border: 2px solid white;
  border-radius: 50%;
  z-index: 2;
}

.entry-point {
  left: -6px;
  top: 50%;
  transform: translateY(-80%);
}

.exit-point {
  right: -6px;
  top: 50%;
  transform: translateY(-80%);
}
</style> 