<template>
  <div class="admin-dashboard-view">
    <AdminSidebar @navigate="handleNavigation" :currentComponent="currentComponent" />
    <div class="main-content">
      <div class="content-wrapper">
        <h1 v-if="currentComponent === 'AdminDashboardContent'">Leads</h1>
        <component 
          :is="currentComponent"
          @refresh="handleRefresh"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdminSidebar from '@/components/AdminSidebar.vue'
import AdminDashboardContent from '@/components/AdminDashboardContent.vue'
import ChatInterface from '@/components/ChatInterface.vue'

export default {
  name: 'AdminDashboardView',
  components: {
    AdminSidebar,
    AdminDashboardContent,
    ChatInterface
  },
  data() {
    return {
      currentComponent: 'AdminDashboardContent'
    }
  },
  methods: {
    handleNavigation(component) {
      this.currentComponent = component;
    },
    handleRefresh() {
      console.log('Refreshing admin dashboard');
    }
  }
}
</script>

<style scoped>
.admin-dashboard-view {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #f8f9fc;
  overflow: hidden;
}

.main-content {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  background-color: #f8f9fc;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrapper:has(> .admin-dashboard-content) {
  max-width: 1400px;
  margin: 0 auto;
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
}
</style>
