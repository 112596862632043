<template>
  <div class="countdown">
    <div class="deadline">Submissions close December 20th, 2024</div>
    <div class="countdown-timer">
      <div class="time-block">
        <div class="number">{{ days }}</div>
        <div class="label">days</div>
      </div>
      <div class="time-block">
        <div class="number">{{ hours }}</div>
        <div class="label">hours</div>
      </div>
      <div class="time-block">
        <div class="number">{{ minutes }}</div>
        <div class="label">mins</div>
      </div>
      <div class="time-block">
        <div class="number">{{ seconds }}</div>
        <div class="label">secs</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      interval: null
    }
  },
  created() {
    this.startTimer()
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    startTimer() {
      const endDate = new Date('2024-12-20T23:59:59').getTime()
      
      this.interval = setInterval(() => {
        const now = new Date().getTime()
        const distance = endDate - now

        if (distance < 0) {
          clearInterval(this.interval)
          this.days = '00'
          this.hours = '00'
          this.minutes = '00'
          this.seconds = '00'
          return
        }

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0')
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0')
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0')
      }, 1000)
    }
  }
}
</script>

<style scoped>
.countdown {
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.deadline {
  color: var(--accent-victory-green);
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.time-block {
  background: var(--accent-sunny-yellow);
  padding: 4px 6px;
  border-radius: 4px;
  min-width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: var(--accent-victory-green);
  font-size: 1rem;
  line-height: 1;
}

.label {
  color: var(--accent-victory-green);
  font-size: 0.65rem;
  opacity: 0.8;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .countdown {
    padding: 0.5rem;
  }
  
  .time-block {
    min-width: 35px;
  }
}
</style> 