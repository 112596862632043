<template>
  <div class="stakeholders-content">
    <h1>Stakeholders</h1>
    <div class="two-column-layout">
      <!-- Left Column -->
      <div class="left-column">
        <div class="info-section">
          <h2>Identify Your Stakeholders</h2>
          <p>Add key stakeholders who will be involved in the AI discovery process.</p>
          <div class="stakeholder-types">
            <div class="stakeholder-type">
              <h3>Decision Makers</h3>
              <p>Leaders/Managers who will approve and oversee processes</p>
            </div>
            <div class="stakeholder-type">
              <h3>End Users</h3>
              <p>Team members or client who use the result of the process</p>
            </div>
            <div class="stakeholder-type">
              <h3>Technical Team</h3>
              <p>IT staff who will support any future integration</p>
            </div>
            <div class="stakeholder-type">
              <h3>Process Owner</h3>
              <p>Team members who creates value with the process and is responsible for its success</p>
            </div>
          </div>
          <div class="add-stakeholder-form">
            <div class="form-row">
              <div class="form-group">
                <input 
                  v-model="newStakeholder.name" 
                  placeholder="Full Name"
                  type="text"
                  class="form-input"
                  :disabled="isSubmitting"
                />
              </div>
              <div class="form-group">
                <input 
                  v-model="newStakeholder.email" 
                  placeholder="Email Address"
                  type="email"
                  class="form-input"
                  :disabled="isSubmitting"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <select 
                  v-model="newStakeholder.role" 
                  class="form-select"
                  :disabled="isSubmitting"
                >
                  <option value="" disabled selected>Select Role</option>
                  <option value="Decision Maker">Decision Maker</option>
                  <option value="End User">End User</option>
                  <option value="Technical Team">Technical Team</option>
                  <option value="Process Owner">Process Owner</option>
                </select>
              </div>
              <div class="form-group">
                <button 
                  @click="addStakeholder" 
                  class="add-button"
                  :disabled="isSubmitting"
                >
                  <span v-if="!isSubmitting">Add Stakeholder</span>
                  <div v-else class="loading-spinner"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column -->
      <div class="right-column">
        <div class="stakeholders-table-container">
          <h2>Current Stakeholders</h2>
          <div v-if="assessmentData.stakeholders && assessmentData.stakeholders.length > 0">
            <div class="table-header">
              <div class="header-cell">Name</div>
              <div class="header-cell">Email</div>
              <div class="header-cell">Role</div>
              <div class="header-cell">Status</div>
              <div class="header-cell">Actions</div>
            </div>
            <div v-for="(stakeholder, index) in assessmentData.stakeholders" 
                 :key="index" 
                 class="table-row"
            >
              <div class="table-cell">{{ stakeholder.name }}</div>
              <div class="table-cell">{{ stakeholder.email }}</div>
              <div class="table-cell">{{ stakeholder.role }}</div>
              <div class="table-cell">
                <span class="status-badge" :class="stakeholder.status">
                  {{ stakeholder.status }}
                </span>
              </div>
              <div class="table-cell">
                <button 
                  @click="initiateRemove(stakeholder)"
                  class="remove-button"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div v-else class="no-stakeholders">
            <p>No Stakeholders</p>
            <p class="subtitle">Please add stakeholders to your project</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal System -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content" :class="modalType">
        <div class="modal-icon">
          <div v-if="modalType === 'loading'" class="loading-spinner"></div>
          <span v-else-if="modalType === 'error'" class="error-icon">!</span>
          <span v-else-if="modalType === 'validation'" class="validation-icon">!</span>
        </div>
        <p>{{ modalMessage }}</p>
        <button v-if="modalType !== 'loading'" @click="closeModal" class="modal-close">Close</button>
      </div>
    </div>

    <!-- Add Confirmation Modal -->
    <div v-if="showConfirmModal" class="modal-overlay">
      <div class="modal-content confirmation">
        <div class="modal-icon warning">
          <span>!</span>
        </div>
        <h3>Remove Stakeholder</h3>
        <p>Are you sure you want to remove this stakeholder?</p>
        <div class="modal-actions">
          <button @click="confirmRemove" class="confirm-button" :disabled="isRemoving">
            <span v-if="!isRemoving">Confirm</span>
            <div v-else class="loading-spinner"></div>
          </button>
          <button @click="closeConfirmModal" class="cancel-button" :disabled="isRemoving">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addStakeholder, removeStakeholder, getCompletedAssessment } from '@/services/api';

export default {
  name: 'StakeholdersContent',
  props: {
    assessmentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false,
      showModal: false,
      modalType: 'loading', // 'loading', 'error', or 'validation'
      modalMessage: '',
      newStakeholder: {
        name: '',
        email: '',
        role: ''
      },
      showConfirmModal: false,
      isRemoving: false,
      stakeholderToRemove: null
    }
  },
  methods: {
    showLoadingModal() {
      this.modalType = 'loading';
      this.modalMessage = 'Adding stakeholder...';
      this.showModal = true;
    },

    showErrorModal(message) {
      this.modalType = 'error';
      this.modalMessage = message;
      this.showModal = true;
    },

    showValidationModal(message) {
      this.modalType = 'validation';
      this.modalMessage = message;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.modalMessage = '';
    },

    async addStakeholder() {
      // Validation
      if (!this.newStakeholder.name) {
        this.showValidationModal('Please enter a name');
        return;
      }
      if (!this.newStakeholder.email) {
        this.showValidationModal('Please enter an email address');
        return;
      }
      if (!this.newStakeholder.role) {
        this.showValidationModal('Please select a role');
        return;
      }

      this.isSubmitting = true;
      this.showLoadingModal();

      try {
        await addStakeholder(this.assessmentData.id, this.newStakeholder);
        const response = await getCompletedAssessment(this.assessmentData.id);
        this.$emit('refresh-assessment');
        
        // Reset form
        this.newStakeholder = {
          name: '',
          email: '',
          role: ''
        };
        this.closeModal();
      } catch (error) {
        console.error('Error adding stakeholder:', error);
        this.showErrorModal(
          'There was an error adding the stakeholder. Please try signing out and signing back in.'
        );
      } finally {
        this.isSubmitting = false;
      }
    },

    initiateRemove(stakeholder) {
      this.stakeholderToRemove = stakeholder;
      this.showConfirmModal = true;
    },

    closeConfirmModal() {
      this.showConfirmModal = false;
      this.stakeholderToRemove = null;
    },

    async confirmRemove() {
      if (!this.stakeholderToRemove) return;
      
      this.isRemoving = true;
      try {
        await removeStakeholder(this.assessmentData.id, this.stakeholderToRemove.email);
        const response = await getCompletedAssessment(this.assessmentData.id);
        this.$emit('refresh-assessment');
        this.closeConfirmModal();
      } catch (error) {
        console.error('Error removing stakeholder:', error);
        this.showErrorModal(
          'There was an error removing the stakeholder. Please try signing out and signing back in.'
        );
      } finally {
        this.isRemoving = false;
      }
    }
  }
}
</script>

<style scoped>
.stakeholders-content {
  padding: 2rem;
}

.two-column-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.left-column, .right-column {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Left column styles */
.stakeholder-types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px 0;
}

.stakeholder-type {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stakeholder-type:hover {
  transform: translateY(-5px);
}

.stakeholder-type h3 {
  color: var(--accent-victory-green);
  margin-bottom: 10px;
}

.stakeholder-type p {
  color: #666;
  margin: 0;
}

.add-stakeholder-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.form-group {
  flex: 1;
  display: flex;
}

.form-input, .form-select, .add-button {
  width: 100%;
  /* height: 42px; */
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.form-select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 1em;
  padding-right: 2rem;
}

.add-button {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.add-button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Right column styles */
.stakeholders-table-container {
  width: 100%;
}

.table-header {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 0.8fr;
  gap: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-weight: 600;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 0.8fr;
  gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
}

.status-badge.pending {
  background-color: var(--accent-sunny-yellow);
  color: #333;
}

.status-badge.active {
  background-color: var(--accent-victory-green);
  color: white;
}

.remove-button {
  background-color: #ff4444;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85rem;
}

h1 {
  color: var(--accent-victory-green);
  margin-bottom: 1rem;
}

h2 {
  color: var(--accent-victory-green);
  margin-bottom: 1.5rem;
}

/* Update existing add-stakeholder class to be removed since we're using the new form */
.add-stakeholder {
  display: none;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.no-stakeholders {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.no-stakeholders p {
  margin: 0;
  font-size: 1.1rem;
}

.no-stakeholders .subtitle {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: #999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modal-icon {
  margin-bottom: 1rem;
}

.error-icon, .validation-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.error-icon {
  background-color: #ff4444;
  color: white;
}

.validation-icon {
  background-color: var(--accent-sunny-yellow);
  color: #333;
}

.modal-content.loading {
  padding: 3rem;
}

.modal-content.loading .loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--accent-victory-green);
  border-top-color: transparent;
}

.modal-content p {
  margin: 1rem 0;
  color: #333;
  font-size: 1.1rem;
}

.modal-close {
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.modal-close:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.modal-content.confirmation {
  max-width: 400px;
}

.modal-content.confirmation h3 {
  color: #333;
  margin: 1rem 0;
}

.modal-icon.warning {
  background-color: var(--accent-sunny-yellow);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.modal-icon.warning span {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.confirm-button, .cancel-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.confirm-button {
  background-color: #ff4444;
  color: white;
}

.confirm-button:hover {
  background-color: #ff6666;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #333;
}

.cancel-button:hover {
  background-color: #d0d0d0;
}

.confirm-button:disabled,
.cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
