<template>
  <div class="sidebar">
    <div class="logo-container">
      <img src="@/assets/logo.png" alt="Victory AI Logo" class="logo">
    </div>
    <nav>
      <ul>
        <li>
          <a @click="navigate('BusinessDashboardContent')" 
             :class="['nav-link', { 'active': currentComponent === 'BusinessDashboardContent' }]">
            Success Roadmap
          </a>
        </li>
        <li>
          <a @click="navigate('AssessmentContent')" 
             :class="['nav-link', { 'active': currentComponent === 'AssessmentContent' }]">
            Initial Assessment
          </a>
        </li>
        <li>
          <a @click="navigate('DiscoveryCallContent')" 
             :class="['nav-link', { 'active': currentComponent === 'DiscoveryCallContent' }]">
            Schedule Call
          </a>
        </li>
        <li>
          <a @click="navigate('StakeholdersContent')" 
             :class="['nav-link', { 'active': currentComponent === 'StakeholdersContent' }]">
            Stakeholders
          </a>
        </li>
        <li>
          <a @click="navigate('ProposalContent')" 
             :class="['nav-link', { 'active': currentComponent === 'ProposalContent' }]">
            Proposal
          </a>
        </li>
        <li>
          <a @click="navigate('ReportsContent')" 
             :class="['nav-link', { 'active': currentComponent === 'ReportsContent' }]">
            Reports
          </a>
        </li>
        <li>
          <a @click="navigate('ProjectsContent')" 
             :class="['nav-link', { 'active': currentComponent === 'ProjectsContent' }]">
            Projects
          </a>
        </li>
        <li>
          <a @click="navigate('SupportContent')" 
             :class="['nav-link', { 'active': currentComponent === 'SupportContent' }]">
            Support
          </a>
        </li>
      </ul>
    </nav>
    <div class="logout-container">
      <button @click="logout" class="logout-button">Logout</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getCompletedAssessment } from '@/services/api'

export default {
  name: 'Sidebar',
  props: {
    currentComponent: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(['setShowHeader', 'setLoginState']),
    async navigate(component) {
      console.log('Navigation - Current Login Status:', this.$store.state.loggedIn);
      
      // If navigating to AssessmentContent, fetch the latest assessment
      if (component === 'AssessmentContent') {
        try {
          const assessmentId = localStorage.getItem('assessment_id');
          console.log('Fetching latest assessment data for ID:', assessmentId);
          const response = await getCompletedAssessment(assessmentId);
          console.log('Latest assessment data received:', response.assessment);
        } catch (error) {
          console.error('Error fetching latest assessment:', error);
        }
      }
      
      this.$emit('navigate', component);
    },
    async logout() {
      console.log('Before Logout - Login Status:', this.$store.state.loggedIn);
      
      // Save email before clearing
      const userEmail = localStorage.getItem('email');
      
      // Clear all items from localStorage
      localStorage.clear();
      
      // Restore email
      if (userEmail) {
        localStorage.setItem('email', userEmail);
      }
      
      // Set login state to false
      this.setLoginState(false);
      
      console.log('After Logout - Login Status:', this.$store.state.loggedIn);
      
      // Navigate to login page
      this.$router.push('/login');
    },
    async handleAssessmentClick(assessmentId) {
      try {
        const response = await getCompletedAssessment(assessmentId);
        // The localStorage will be automatically updated by the service
        // You might want to update your Vuex store or emit an event
        this.$store.commit('updateAssessmentData', response.data.assessment);
      } catch (error) {
        console.error('Error loading assessment:', error);
        // Handle error appropriately
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  height: 95vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  z-index: 10; /* Ensure sidebar is above other content */
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 180px;
  height: auto;
}

nav {
  flex-grow: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
}

.nav-link {
  text-decoration: none;
  color: var(--accent-victory-green);
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  transition: all 0.3s ease;
  display: block;
  padding: 10px;
  border-radius: 5px;
}

.nav-link:hover, .nav-link.active {
  background-color: var(--secondary-background-sky-blue);
  color: white;
  transform: translateX(5px);
}

.logout-container {
  margin-top: auto;
}

.logout-button {
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}

.logout-button:hover {
  background-color: #c82333;
}
</style>
