export default {
  namespaced: true,
  state: {
    COLUMN_COUNT: 5,
    departments: [
      { id: 'sales_dept', name: 'Sales Department' },
      { id: 'marketing_dept', name: 'Marketing Department' },
      { id: 'hr_dept', name: 'Human Resources' }
    ],
    swimlanes: {
      'sales_process_1': {
        id: 'sales_process_1',
        name: 'Sales Process Flowchart',
        departmentId: 'sales_dept',
        swimDepartments: [
          { id: 'customer', name: 'CUSTOMER' },
          { id: 'sales', name: 'SALES' },
          { id: 'stocks', name: 'STOCKS' },
          { id: 'finance', name: 'FINANCE' }
        ],
        columns: Array(5).fill().map(() => Array(4).fill(null)),
        nodes: {
          'node1': { department: 'customer', content: 'Place a Product Order', icon: 'fas fa-shopping-basket', type: 'node', style: 'start' },
          'node2': { department: 'sales', content: 'Confirm if order is received', icon: 'fas fa-box', type: 'node' },
          'node3': { department: 'stocks', content: 'Is the product in stock?', type: 'decision' },
          'node4': { department: 'sales', content: 'Cancel the order', icon: 'fas fa-times-circle', type: 'node', style: 'cancel' },
          'node5': { department: 'finance', content: 'Check credit card', icon: 'fas fa-credit-card', type: 'node' },
          'node6': { department: 'customer', content: 'Finish', icon: 'fas fa-check-circle', type: 'node', style: 'end' },
          'node7': { department: 'stocks', content: 'Deliver the order', icon: 'fas fa-truck', type: 'node', style: 'delivery' },
          'node8': { department: 'finance', content: 'Processing the payment', icon: 'fas fa-money-bill-wave', type: 'node', style: 'payment' }
        },
        nodeCounter: 8
      }
    },
    nodes: [
      {
        id: 'node1',
        col: 1,
        row: 0,
        content: 'Start',
        type: 'start',
        department: 'customer',
        icon: 'fas fa-play',
        connections: {
          inputs: [],
          outputs: ['node2']
        }
      },
      {
        id: 'node2',
        col: 2,
        row: 1,
        content: 'Node 2',
        type: 'process',
        department: 'sales',
        icon: 'fas fa-cog',
        connections: {
          inputs: ['node1'],
          outputs: []
        }
      }
    ],
    colorScheme: {
      backgrounds: [
        'var(--secondary-background-sky-blue)', // Sky blue
        '#FFD892',                         // Light yellow
        '#2D7A62',                         // Light green
        '#8FD4E6',                         // Light blue
        '#4B3832',                         // Rich brown
        '#FFE5B9',                         // Pale yellow
        '#5E4B3B',                         // Warm brown
        '#A8DFF0',                         // Powder blue
        '#614B3C',                         // Deep brown
        '#FFE0A3',                         // Warm yellow
        '#523B35',                         // Dark brown
        '#7FCAE0',                         // Ocean blue
        '#483432',                         // Rustic brown
        '#FFD67F',                         // Golden yellow
        '#574139',                         // Coffee brown
        '#B8E6F5',                         // Ice blue
        '#3D2F2F',                         // Deep charcoal
        '#725A48',                         // Warm brown
        '#4A6670'                          // Steel blue (new color replacing victory green)
      ]
    }
  },

  getters: {
    getDepartmentColor: (state) => (index) => {
      return state.colorScheme.backgrounds[index % state.colorScheme.backgrounds.length]
    },

    getDepartmentSwimlanes: (state) => (departmentId) => {
      return Object.values(state.swimlanes)
        .filter(swimlane => swimlane.departmentId === departmentId)
        .map(swimlane => ({
          ...swimlane,
          swimDepartments: swimlane.swimDepartments.map(dept => ({
            ...dept
          }))
        }))
    },

    getSwimlane: (state) => (swimlaneId) => {
      return state.swimlanes[swimlaneId]
    },

    getNodeById: (state) => (swimlaneId, nodeId) => {
      return state.swimlanes[swimlaneId]?.nodes[nodeId]
    },

    getColumnNodes: (state) => (swimlaneId, columnIndex) => {
      const swimlane = state.swimlanes[swimlaneId]
      if (!swimlane) return []
      
      return swimlane.columns[columnIndex].map(nodeId => 
        nodeId ? swimlane.nodes[nodeId] : { content: null }
      )
    },

    getNodes: state => state.nodes,
    getNodeById: state => id => state.nodes.find(node => node.id === id),
    getNodeAtPosition: state => (col, row) => state.nodes.find(node => node.col === col && node.row === row)
  },

  mutations: {
    ADD_SWIMLANE(state, { departmentId, name }) {
      const swimlaneId = `swimlane_${Date.now()}`
      state.swimlanes[swimlaneId] = {
        id: swimlaneId,
        name,
        departmentId,
        swimDepartments: [], // Will be set separately
        nodes: {},
        columns: [],
        nodeCounter: 0
      }
      return swimlaneId
    },

    ADD_NODE(state, { swimlaneId, node }) {
      const swimlane = state.swimlanes[swimlaneId]
      if (!swimlane) return

      const nodeId = `node${++swimlane.nodeCounter}`
      swimlane.nodes[nodeId] = {
        id: nodeId,
        department: node.department,
        content: node.content,
        icon: node.icon,
        type: node.type,
        style: node.style
      }
      return nodeId
    },

    REMOVE_NODE(state, { swimlaneId, columnIndex, rowIndex }) {
      const swimlane = state.swimlanes[swimlaneId]
      if (!swimlane) return

      const nodeId = swimlane.columns[columnIndex][rowIndex]
      if (nodeId) {
        swimlane.nodes[nodeId] = {
          ...swimlane.nodes[nodeId],
          content: null,
          icon: null,
          type: 'node',
          style: null
        }
      }
    },

    INITIALIZE_EXAMPLE_SWIMLANE(state) {
      const columns = Array(state.COLUMN_COUNT).fill().map(() => Array(4).fill(null));
      
      // Place nodes in specific positions
      columns[0][0] = 'node1';  // First column, customer row
      columns[1][1] = 'node2';  // Second column, sales row
      columns[2][2] = 'node3';  // Third column, stocks row
      columns[3][1] = 'node4';  // Fourth column, sales row
      columns[3][3] = 'node5';  // Fourth column, finance row
      columns[4][0] = 'node6';  // Fifth column, customer row
      columns[4][2] = 'node7';  // Fifth column, stocks row
      columns[4][3] = 'node8';  // Fifth column, finance row

      state.swimlanes['sales_process_1'].columns = columns;
    },

    UPDATE_NODE_POSITION(state, { nodeId, col, row }) {
      const node = state.nodes.find(n => n.id === nodeId)
      if (node) {
        node.col = col
        node.row = row
      }
    },

    UPDATE_NODE_CONNECTIONS(state, { nodeId, inputs, outputs }) {
      const node = state.nodes.find(n => n.id === nodeId)
      if (node) {
        node.connections = { inputs, outputs }
      }
    },

    SET_SWIMLANE_DATA(state, data) {
      state.swimlanes = data.swimlanes.reduce((acc, swimlane) => {
        // Map departments with their proper colors
        const swimDepartments = swimlane.swimDepartments.map((dept, index) => ({
          ...dept,
          color: state.colorScheme.backgrounds[index % state.colorScheme.backgrounds.length],
          // Set isLightTheme based on the color brightness
          isLightTheme: [
            'var(--secondary-background-sky-blue)',
            '#FFD892',
            '#FFE5B9',
            '#A8DFF0',
            '#FFE0A3',
            '#7FCAE0',
            '#FFD67F',
            '#B8E6F5'
          ].includes(state.colorScheme.backgrounds[index % state.colorScheme.backgrounds.length])
        }))

        acc[swimlane.id] = {
          ...swimlane,
          swimDepartments
        }
        return acc
      }, {})
    },

    ADD_DEPARTMENT(state, { swimlaneId, department, index }) {
      const swimlane = state.swimlanes[swimlaneId]
      if (swimlane) {
        // Get array of currently used colors
        const usedColors = swimlane.swimDepartments.map(dept => dept.color)
        
        // Get available colors from the scheme
        const availableColors = state.colorScheme.backgrounds.filter(
          color => !usedColors.includes(color)
        )
        
        // If we've used all colors, start over with the full scheme
        const colorToUse = availableColors.length > 0 
          ? availableColors[0] 
          : state.colorScheme.backgrounds[index % state.colorScheme.backgrounds.length]

        // Insert department with new color
        swimlane.swimDepartments.splice(index, 0, {
          ...department,
          color: colorToUse,
          // Set isLightTheme based on the color brightness
          isLightTheme: [
            'var(--secondary-background-sky-blue)',
            '#FFD892',
            '#FFE5B9',
            '#A8DFF0',
            '#FFE0A3',
            '#7FCAE0',
            '#FFD67F',
            '#B8E6F5'
          ].includes(colorToUse)
        })
      }
    },
    
    REMOVE_DEPARTMENT(state, { swimlaneId, index }) {
      const swimlane = state.swimlanes[swimlaneId]
      if (swimlane) {
        // Remove the department from swimDepartments
        swimlane.swimDepartments.splice(index, 1)

        // Remove any nodes in this row
        const cellHeight = 250
        const nodesToRemove = []
        
        // Find all nodes in the row being removed
        for (const nodeId in swimlane.nodes) {
          const node = swimlane.nodes[nodeId]
          if (node && node.position) {
            const nodeRow = Math.floor(node.position.y / cellHeight)
            if (nodeRow === index) {
              nodesToRemove.push(nodeId)
            }
          }
        }
        
        // Remove the nodes
        nodesToRemove.forEach(nodeId => {
          delete swimlane.nodes[nodeId]
          // Also remove any connections involving these nodes
          if (swimlane.connections) {
            swimlane.connections = swimlane.connections.filter(conn => 
              conn.source !== nodeId && conn.target !== nodeId
            )
          }
        })

        // Update positions of nodes below the removed row
        for (const nodeId in swimlane.nodes) {
          const node = swimlane.nodes[nodeId]
          if (node && node.position) {
            const nodeRow = Math.floor(node.position.y / cellHeight)
            if (nodeRow > index) {
              node.position.y -= cellHeight // Move up one row
            }
          }
        }

        // Update the grid if columns exist
        if (swimlane.columns) {
          swimlane.columns.forEach(column => {
            column.splice(index, 1)
          })
        }
      }
    },

    REORDER_DEPARTMENTS(state, { swimlaneId, fromIndex, toIndex }) {
      const swimlane = state.swimlanes[swimlaneId]
      if (swimlane) {
        // Remove department from old position with ALL its properties
        const [movedDept] = swimlane.swimDepartments.splice(fromIndex, 1)
        
        // Insert at new position - the department keeps ALL its properties including colors
        swimlane.swimDepartments.splice(toIndex, 0, movedDept)

        // Update node positions
        const cellHeight = 250
        for (const nodeId in swimlane.nodes) {
          const node = swimlane.nodes[nodeId]
          if (node && node.position) {
            const row = Math.floor(node.position.y / cellHeight)
            let newRow = row
            
            if (row === fromIndex) {
              newRow = toIndex
            } else if (fromIndex < toIndex) {
              if (row > fromIndex && row <= toIndex) newRow = row - 1
            } else {
              if (row >= toIndex && row < fromIndex) newRow = row + 1
            }
            
            if (newRow !== row) {
              node.position.y = newRow * cellHeight
            }
          }
        }
      }
    }
  },

  actions: {
    createSwimlane({ commit }, { departmentId, name }) {
      return commit('ADD_SWIMLANE', { departmentId, name })
    },

    removeNode({ commit }, { swimlaneId, columnIndex, rowIndex }) {
      commit('REMOVE_NODE', { swimlaneId, columnIndex, rowIndex })
    },

    initializeExampleData({ commit }) {
      const exampleData = {
        swimlanes: [{
          id: 'sales_process_1',
          name: 'Sales Process Flowchart',
          departmentId: 'sales_dept',
          swimDepartments: [
            { id: 'customer', name: 'CUSTOMER', isLightTheme: true },
            { id: 'sales', name: 'SALES', isLightTheme: false },
            { id: 'stocks', name: 'STOCKS', isLightTheme: false },
            { id: 'finance', name: 'FINANCE', isLightTheme: false }
          ]
        }]
      }
      commit('SET_SWIMLANE_DATA', exampleData)
    },

    updateNodePosition({ commit }, payload) {
      commit('UPDATE_NODE_POSITION', payload)
    },

    addDepartment({ commit }, { swimlaneId, department, index }) {
      commit('ADD_DEPARTMENT', { swimlaneId, department, index })
    },
    
    removeDepartment({ commit }, payload) {
      commit('REMOVE_DEPARTMENT', payload)
    },

    reorderDepartments({ commit }, payload) {
      commit('REORDER_DEPARTMENTS', payload)
    }
  }
}
